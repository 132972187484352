import clsx from "clsx";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import useModal from "../../../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import { unitsType } from "../../../../common/types";
import { getCriterionModalId } from "../../utils";
import CriterionFooter from "../common/CriterionFooter";
import { CriterionLabel } from "../common/CriterionLabel";
import CriterionTabs from "../common/CriterionTabs";
import useDropdownPosition from "../useDropdownPosition";
import { RangeFields } from "./RangeFields";

export function Range({
	clearable = true,
	criterionKey,
	currentInput,
	datesRange,
	guideline,
	inputRange,
	label,
	onClearInput,
	onInputChange,
	prefix,
	quickSearch,
	units,
	yearsRange,
	...props
}) {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const {
		isDropdownBelowViewport,
		isDropdownRightOfViewport,
		isDropdownHidden,
	} = useDropdownPosition(dropdownRef, dropdownIsOpened, quickSearch);
	const classes = clsx("criterion range-dropdown dropdown grid__col-12", {
		"dates-dropdown": datesRange,
		"years-picker-dropdown": yearsRange,
	});
	const modalId = getCriterionModalId(criterionKey);
	const unit = currentInput.get("unit", "");

	const handleInputChange = (key, input) =>
		onInputChange(currentInput.set(key, input));

	const handleDropdownClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
		units &&
			!isEmpty(units) &&
			!unit &&
			handleInputChange("unit", units[0].key);
	};

	const handleSaveInput = () => {
		onDropdownToggle();
		onCloseModal(getCriterionModalId(criterionKey));
	};

	const handleClearInput = () => {
		onClearInput();
		onDropdownToggle();
		onCloseModal(getCriterionModalId(criterionKey));
	};

	return (
		<div ref={dropdownRef} className={classes}>
			<button
				type="button"
				className={clsx("dropdown-toggle", {
					"choices-visible": inputRange,
				})}
				aria-expanded={dropdownIsOpened}
				data-criterion={true}
				onClick={handleDropdownClick}
			>
				<CriterionLabel label={label} prefix={prefix} />
				{inputRange && (
					<span className="dropdown-choices-counter">
						{inputRange + " " + unit}
					</span>
				)}
			</button>

			{dropdownIsOpened && (
				<ConditionalWrapper
					condition={isMobile && dropdownIsOpened}
					wrapper={(children) => (
						<ActionsMenu
							id={modalId}
							title={label}
							onClose={handleSaveInput}
						>
							{children}
						</ActionsMenu>
					)}
				>
					<div
						className={clsx("dropdown-menu", {
							"dropdown-menu--opened": dropdownIsOpened,
							"dropdown-menu--inverted-y":
								isDropdownBelowViewport,
							"dropdown-menu--inverted-x":
								isDropdownRightOfViewport,
							"dropdown-menu--hidden": isDropdownHidden,
						})}
						data-criterion={true}
						tabIndex={-1}
					>
						<CriterionTabs
							input={unit}
							tabsKey="unit"
							items={units}
							currentInput={currentInput}
							onInputChange={onInputChange}
						/>

						<RangeFields
							criterionKey={criterionKey}
							datesRange={datesRange}
							label={label}
							guideline={guideline}
							onInputChange={handleInputChange}
							isMobile={isMobile}
							yearsRange={yearsRange}
							{...props}
						/>

						<CriterionFooter
							guideline={datesRange ? guideline : null}
							onSaveInput={handleSaveInput}
							onClearInput={handleClearInput}
							clearLabel={clearable ? "Clear all" : null}
						/>
					</div>
				</ConditionalWrapper>
			)}
		</div>
	);
}

Range.propTypes = {
	clearable: PropTypes.bool,
	criterionKey: PropTypes.string.isRequired,
	currentInput: ImmutablePropTypes.mapOf(PropTypes.string).isRequired,
	datesRange: PropTypes.bool.isRequired,
	guideline: PropTypes.string,
	inputRange: PropTypes.string,
	label: PropTypes.string.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onClearInput: PropTypes.func.isRequired,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool.isRequired,
	units: unitsType,
	yearsRange: PropTypes.bool.isRequired,
};
