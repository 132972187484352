import { useEffect } from "react";
import {
	releaseFocusInModal,
	trapFocusInModal,
} from "../../../ui/common/modals";

const KEY_NAME_ESC = "Escape";
const KEY_EVENT_TYPE = "keydown";

export default function useKeyboardNavigation(
	onCloseModal,
	modalRef,
	openedModalsExist,
) {
	useEffect(() => {
		if (openedModalsExist) {
			const modal = modalRef.current;

			const handleEscapeKeyPress = (event) => {
				if (event.key === KEY_NAME_ESC) {
					const hasDropdownOpened = modal.querySelector(
						".dropdown-toggle[aria-expanded=true]",
					);
					if (!hasDropdownOpened) {
						onCloseModal();
					}
				}
			};

			trapFocusInModal(modal);
			document.addEventListener(KEY_EVENT_TYPE, handleEscapeKeyPress);

			return () => {
				releaseFocusInModal(modal);
				document.removeEventListener(
					KEY_EVENT_TYPE,
					handleEscapeKeyPress,
				);
			};
		}
	}, [openedModalsExist]);
}
