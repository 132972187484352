import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import useModal from "../../../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import { trim } from "../../../utils";
import { getCriterionModalId } from "../../utils";
import CriterionFooter from "../common/CriterionFooter";
import { CriterionLabel } from "../common/CriterionLabel";
import { createDatePicker } from "../range/inputs";
import useDropdownPosition from "../useDropdownPosition";

export const DateComponent = ({
	clearable = true,
	criterionKey,
	currentInput,
	filterDate,
	label,
	onInputChange,
	onClearInput,
	prefix,
	quickSearch,
	valid = true,
	years = [],
}) => {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);
	const modalId = getCriterionModalId(criterionKey);

	const componentClass = clsx("criterion date-criterion dropdown", {
		required: !valid,
	});
	const dropdownClass = clsx("dropdown-menu date-picker-criterion", {
		"dropdown-menu--opened": dropdownIsOpened,
		"dropdown-menu--inverted-y": isDropdownBelowViewport,
		"dropdown-menu--hidden": isDropdownHidden,
	});

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
	};

	const handleSaveInput = () => {
		onDropdownToggle();
		onCloseModal(modalId);
	};

	return (
		<div
			ref={dropdownRef}
			className={componentClass}
			data-criterion={true}
			tabIndex={-1}
		>
			<button
				type="button"
				className={clsx("dropdown-toggle", {
					"choices-visible": currentInput,
				})}
				aria-expanded={dropdownIsOpened}
				data-criterion={true}
				onClick={handleDropdownToggleClick}
			>
				<CriterionLabel label={label} prefix={prefix} />
			</button>

			{currentInput && (
				<span className="dropdown-choices-counter">
					{trim(currentInput)}
				</span>
			)}

			<ConditionalWrapper
				condition={isMobile}
				wrapper={(children) => (
					<ActionsMenu
						id={modalId}
						title={label}
						onClose={handleSaveInput}
					>
						{children}
					</ActionsMenu>
				)}
			>
				<div className={dropdownClass}>
					{createDatePicker(
						`${criterionKey}${quickSearch ? "_quick_search" : ""}`,
						"",
						onInputChange,
						"",
						currentInput,
						filterDate,
						years,
						criterionKey,
					)}
					<CriterionFooter
						clearLabel={clearable ? "Clear" : null}
						onSaveInput={handleSaveInput}
						onClearInput={onClearInput}
					/>
				</div>
			</ConditionalWrapper>
		</div>
	);
};

DateComponent.propTypes = {
	clearable: PropTypes.bool,
	criterionKey: PropTypes.string.isRequired,
	currentInput: PropTypes.string.isRequired,
	filterDate: PropTypes.func,
	label: PropTypes.string.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onClearInput: PropTypes.func.isRequired,
	quickSearch: PropTypes.bool.isRequired,
	prefix: PropTypes.string,
	valid: PropTypes.bool,
	years: PropTypes.array,
};
