import { buildYearChoices, buildYearOptions } from "./utils";

export const MTOWUnits = [
	{ key: "kg", value: "Kilos (kg)" },
	{ key: "lb", value: "Pounds (lb)" },
];

export const contactTitle = [
	{ key: "Mr", value: "Mr" },
	{ key: "Ms", value: "Ms" },
];

export const yesNoPartial = [
	{ key: "yes", value: "Yes" },
	{ key: "no", value: "No" },
	{ key: "partial", value: "Partial" },
];

export const yesNoAny = [
	{ key: null, value: "Any" },
	{ key: "yes", value: "Yes" },
	{ key: "no", value: "No" },
];

export const zeroOneAny = [
	{ key: null, value: "Any" },
	{ key: "1", value: "Yes" },
	{ key: "0", value: "No" },
];

export const regionsLink = {
	content: "Manage Regions",
	url: "/account-management/regions",
};

const currentYear = new Date().getFullYear();

export const winnersAndLosersYears = buildYearOptions(1903, currentYear);
export const yomYears = buildYearChoices(1900, currentYear).reverse();

export const flightType = [
	{ key: "domestic", value: "Domestic" },
	{ key: "international", value: "International" },
];

export const display = [
	{ key: "capacity", value: "Capacity" },
	{ key: "frequency", value: "Frequency" },
	{ key: "ask", value: "Available Seat Kilometers (ASK)" },
	{ key: "asm", value: "Available Seat Miles (ASM)" },
];

export const airportAutoSuggest = "/api/v1/autosuggest/airports";

export const constructionSiteAutoSuggest =
	"/api/v1/autosuggest/aircraft/construction_site";

export const mroLocationsAutoSuggest = "/api/v1/autosuggest/mro-locations";
