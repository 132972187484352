import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "../../../../common/components/dropdown/Dropdown";
import { buildMonthOptions } from "../../../utils";

export function DatePickerHeader({
	date,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	years,
}) {
	const year = date.getFullYear();
	const months = buildMonthOptions();
	const month = months.find((item) => item.key === date.getMonth()).value;

	const isPrevMonthDisabled =
		(month === "January" && year <= years[0]?.key) ||
		prevMonthButtonDisabled;

	const isNextMonthDisabled =
		(month === "December" && year >= years[years.length - 1]?.key) ||
		nextMonthButtonDisabled;

	return (
		<div className="react-datepicker__header--custom--wrapper">
			<button
				type="button"
				className="react-datepicker__navigation react-datepicker__navigation--previous"
				onClick={decreaseMonth}
				disabled={isPrevMonthDisabled}
			>
				Previous Month
			</button>

			<div className="react-datepicker__current-month">
				{month + " " + year}
			</div>

			<div className="react-datepicker__select-month-and-year">
				<Dropdown
					activeItem={month}
					additionalClasses={{ dropdown: "dropdown--light" }}
					disableMobile
					dropdownItems={months}
					dropdownKey="datepicker_month"
					dropdownToggle={month}
					label="Month"
					onDropdownItemClick={(id) => changeMonth(id)}
					preselected
					scrollToView
				/>

				<Dropdown
					activeItem={year.toString()}
					additionalClasses={{ dropdown: "dropdown--light" }}
					dropdownKey="datepicker_year"
					disableMobile
					dropdownItems={years}
					dropdownToggle={year.toString()}
					label="Year"
					onDropdownItemClick={(id, name) => changeYear(name)}
					scrollToView
					preselected
				/>
			</div>

			<button
				type="button"
				className="react-datepicker__navigation react-datepicker__navigation--next"
				onClick={increaseMonth}
				disabled={isNextMonthDisabled}
			>
				Next Month
			</button>
		</div>
	);
}

DatePickerHeader.propTypes = {
	date: PropTypes.object.isRequired,
	years: PropTypes.array,
	changeYear: PropTypes.func.isRequired,
	changeMonth: PropTypes.func.isRequired,
	decreaseMonth: PropTypes.func.isRequired,
	filterDate: PropTypes.func,
	increaseMonth: PropTypes.func.isRequired,
	prevMonthButtonDisabled: PropTypes.bool.isRequired,
	nextMonthButtonDisabled: PropTypes.bool.isRequired,
};
