import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { ActionsMenu } from "../../../../common/components/ActionsMenu";
import { ConditionalWrapper } from "../../../../common/components/ConditionalWrapper";
import { useDropdown } from "../../../../common/components/dropdown/useDropdown";
import useModal from "../../../../common/components/modals/useModal";
import { useAccessWindowSize } from "../../../../common/layout/useAccessWindowSize";
import {
	forecastingInputType,
	forecastingTypesType,
} from "../../../../common/types";
import { getCriterionModalId } from "../../utils";
import useValidation from "../../validation/useValidation";
import CriterionFooter from "../common/CriterionFooter";
import { CriterionLabel } from "../common/CriterionLabel";
import CriterionTabs from "../common/CriterionTabs";
import { FromToDropdowns } from "../dropdown/FromToDropdowns";
import useDropdownPosition from "../useDropdownPosition";
import { ForecastAmountField } from "./ForecastAmountField";
import { useLoadForecastingData } from "./useLoadForecastingData";
import { dateRangeCheck } from "./validation";

export function ForecastComponent({
	criterionKey,
	currentInput,
	endpoint,
	label,
	onClearInput,
	onInputChange,
	prefix,
	quickSearch,
	types,
}) {
	const { dropdownRef, dropdownIsOpened, onDropdownToggle } = useDropdown();
	const { data, loading, error } = useLoadForecastingData(
		endpoint,
		dropdownIsOpened,
		currentInput,
		onInputChange,
	);
	const type = currentInput.get("type", "");
	const { isMobile } = useAccessWindowSize();
	const { onOpenModal, onCloseModal } = useModal();
	const { isDropdownBelowViewport, isDropdownHidden } = useDropdownPosition(
		dropdownRef,
		dropdownIsOpened,
		quickSearch,
	);

	const { valid } = useValidation(criterionKey);
	const modalId = getCriterionModalId(criterionKey);

	const handleDropdownToggleClick = () => {
		onDropdownToggle();
		onOpenModal(modalId);
	};

	const handleInputChange = (value) => {
		onInputChange(value);
	};

	const handleClear = () => {
		onDropdownToggle();
		onClearInput();
	};

	const handleSaveInput = () => {
		onDropdownToggle();
		onCloseModal(modalId);
	};

	let content;
	if (loading || (!error && valid && currentInput.isEmpty())) {
		content = <p className="forecast__fields">Loading...</p>;
	} else if (error) {
		content = (
			<p className="forecast__fields error">
				<span className="error-text">
					Failed to load forecasting data.
				</span>
			</p>
		);
	} else {
		content = (
			<>
				<CriterionTabs
					input={type}
					tabsKey="type"
					items={types}
					currentInput={currentInput}
					onInputChange={handleInputChange}
				/>

				<div className="forecast__fields flex">
					<ForecastAmountField
						type={type}
						valid={valid}
						currentInput={currentInput}
						onInputChange={handleInputChange}
						quickSearch={quickSearch}
					/>

					<FromToDropdowns
						apiData={data}
						currentInput={currentInput}
						dateRangeCheck={dateRangeCheck}
						onInputChange={onInputChange}
					/>
				</div>

				<CriterionFooter
					onSaveInput={handleSaveInput}
					onClearInput={handleClear}
				/>
			</>
		);
	}

	const classes = clsx("criterion forecast-criterion dropdown grid__col-12", {
		"criterion-error": !valid,
	});

	return (
		<div
			ref={dropdownRef}
			className={classes}
			data-criterion={true}
			tabIndex={-1}
		>
			<button
				type="button"
				className="dropdown-toggle"
				aria-expanded={dropdownIsOpened}
				data-criterion={true}
				onClick={handleDropdownToggleClick}
			>
				<CriterionLabel label={label} prefix={prefix} />
			</button>

			{dropdownIsOpened && (
				<ConditionalWrapper
					condition={isMobile}
					wrapper={(children) => (
						<ActionsMenu
							id={modalId}
							title={label}
							onClose={handleSaveInput}
						>
							{children}
						</ActionsMenu>
					)}
				>
					<div
						className={clsx("dropdown-menu", {
							"dropdown-menu--opened": dropdownIsOpened,
							"dropdown-menu--inverted-y":
								isDropdownBelowViewport,
							"dropdown-menu--hidden": isDropdownHidden,
						})}
					>
						{content}
					</div>
				</ConditionalWrapper>
			)}
		</div>
	);
}

ForecastComponent.propTypes = {
	label: PropTypes.string.isRequired,
	endpoint: PropTypes.string.isRequired,
	criterionKey: PropTypes.string.isRequired,
	currentInput: forecastingInputType.isRequired,
	types: forecastingTypesType,
	quickSearch: PropTypes.bool.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onClearInput: PropTypes.func.isRequired,
	prefix: PropTypes.string,
};
