import { setValidationResult } from "../../search/criteria/validation/actions";
import {
	isFormValid,
	validateForm,
} from "../../search/criteria/validation/validation";
import { clearResult } from "../../search/result/actions";
import { validateColumnInput } from "../../search/views/edit/utils";
import { addToast, clearToasts } from "../components/toasts/actions";
import { clearInput, setDefaultInput } from "../input/actions";

export const HASH_CHANGED = "HASH_CHANGED";
export const HASH_CHANGE = "HASH_CHANGE";
export const UPDATE_SEARCH_INPUT = "UPDATE_SEARCH_INPUT";
export const UPDATE_SEARCH_PARAMETERS = "UPDATE_SEARCH_PARAMETERS";
export const RESET_SEARCH_DATA = "RESET_SEARCH_DATA";
export const CLEAR_SEARCH_DATA = "CLEAR_SEARCH_DATA";

export function updateSearchInput(input) {
	return {
		type: UPDATE_SEARCH_INPUT,
		data: {
			input,
		},
	};
}

export function updateSearchParameters(parameters) {
	return {
		type: UPDATE_SEARCH_PARAMETERS,
		data: {
			parameters,
		},
	};
}

export function resetSearchData(data) {
	return {
		type: RESET_SEARCH_DATA,
		data,
	};
}

export function clearSearchData() {
	return {
		type: CLEAR_SEARCH_DATA,
	};
}

export const submitForm = (viewData, clearModals) => {
	return (dispatch, getState) => {
		const state = getState();
		const roles = state.get("roles");
		const currentInput = state.get("input");
		const hashInput = state.getIn(["hash", "input"]);
		const validationRules = state.getIn(["search", "validation", "rules"]);
		const columns = state.getIn(["search", "columns"]);
		const { validationResult, toast } = validateForm(
			validationRules,
			currentInput,
		);
		const valid = isFormValid(validationResult);
		const selectedColumns = state.getIn(["hash", "parameters", "columns"]);
		const isNewInput = !currentInput.equals(hashInput);

		dispatch(setValidationResult(validationResult));

		if (selectedColumns) {
			viewData.columns = mergeViewColumns(
				viewData.columns,
				selectedColumns,
			);
			viewData.columns = validateColumnInput(
				viewData.columns,
				columns,
				currentInput,
				roles,
			);
		}

		if (valid) {
			dispatch(updateSearchInput(currentInput));
			dispatch(
				updateSearchParameters(
					isNewInput ? { ...viewData, page: 0 } : viewData,
				),
			);
			dispatch(clearToasts());
			clearModals();
		} else {
			dispatch(addToast(toast));
			dispatch(clearResult());
		}
	};
};

const mergeViewColumns = (viewColumns, selectedColumns) => {
	if (!viewColumns) {
		return selectedColumns;
	}

	const mergedColumns = [];
	let viewCounter = 0;
	let selectedCounter = 0;
	viewColumns = viewColumns.split(",");
	selectedColumns = selectedColumns.split(",");

	while (true) {
		const viewColumn = viewColumns[viewCounter];
		let selectedColumn = selectedColumns[selectedCounter];

		if (!viewColumn && !selectedColumn) {
			break;
		}

		if (!viewColumn) {
			mergedColumns.push(selectedColumn);
			selectedCounter++;
			continue;
		}

		if (!selectedColumns.includes(viewColumn)) {
			viewCounter++;
			continue;
		}

		if (viewColumn !== selectedColumn && selectedColumn) {
			while (viewColumn !== selectedColumn) {
				mergedColumns.push(selectedColumn);
				selectedCounter++;
				selectedColumn = selectedColumns[selectedCounter];
				if (!selectedColumn) {
					break;
				}
			}
		}

		mergedColumns.push(viewColumn);
		viewCounter++;
		selectedCounter++;
	}

	return mergedColumns.join(",");
};

export const clearSearch = () => {
	return (dispatch, getState) => {
		const state = getState();
		const userDefinedInput = state.getIn([
			"search",
			"quickSearchCriteria",
			"input",
		]);

		if (userDefinedInput) {
			dispatch(setDefaultInput(userDefinedInput));
		} else {
			dispatch(clearInput());
		}

		dispatch(clearSearchData());
		dispatch(clearResult());
	};
};
