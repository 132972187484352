import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { AccordionItem } from "../../../../common/components/accordion/AccordionItem";
import useRolesVisibility from "../../../../common/roles/useRolesVisibility";
import {
	criteriaGroupType,
	customizeQuickSearchRadiosType,
	customizeQuickSearchSelectedChoicesType,
	quickSearchInputType,
} from "../../../../common/types";
import { CustomizeQuickSearchCheckbox } from "./CustomizeQuickSearchCheckbox";
import { CustomizeQuickSearchRadio } from "./CustomizeQuickSearchRadio";
import { useCustomizeQuickSearchVisibilityState } from "./useCustomizeQuickSearchVisibilityState";

export function CustomizeQuickSearchGroup({
	group,
	input,
	onInputChange,
	onSelectedCriteriaChange,
	maxCountOfCriteriaSelected,
	radios,
	selectedCriteria,
}) {
	const {
		name,
		criteria,
		key,
		visibilitySelector,
		requiredRoles = null,
	} = group;
	const visible = useCustomizeQuickSearchVisibilityState(visibilitySelector);
	const { hasRequiredRoles } = useRolesVisibility(requiredRoles);

	if (!visible || !hasRequiredRoles) {
		return null;
	}

	const radiosExist = radios.length > 0;
	const classes = clsx("checkbox-group", {
		"grid__col-12": radiosExist,
		"grid__col-md-6": radiosExist,
		"grid__col-auto": radiosExist,
	});

	return (
		<AccordionItem accordionKey={key} label={name}>
			{radios &&
				radios.map((radio) => (
					<CustomizeQuickSearchRadio
						key={radio}
						input={input}
						group={group}
						onInputChange={onInputChange}
						radioKey={radio}
					/>
				))}

			<div className={classes}>
				<div className="grid align-top">
					{Object.entries(criteria).map(([key, value]) => {
						const selected = selectedCriteria.includes(key);
						const criterion = value.factory(key).props;
						return (
							<CustomizeQuickSearchCheckbox
								key={key}
								criterionKey={key}
								label={criterion.label}
								onChange={onSelectedCriteriaChange}
								radios={radios}
								requiredRoles={criterion.requiredRoles}
								checked={selected}
								disabled={
									maxCountOfCriteriaSelected && !selected
								}
								selectedCriteria={selectedCriteria}
								visibilitySelector={
									criterion.visibilitySelector
								}
							/>
						);
					})}
				</div>
			</div>
		</AccordionItem>
	);
}

CustomizeQuickSearchGroup.propTypes = {
	group: criteriaGroupType.isRequired,
	input: quickSearchInputType,
	onInputChange: PropTypes.func.isRequired,
	onSelectedCriteriaChange: PropTypes.func.isRequired,
	maxCountOfCriteriaSelected: PropTypes.bool.isRequired,
	radios: customizeQuickSearchRadiosType.isRequired,
	selectedCriteria: customizeQuickSearchSelectedChoicesType.isRequired,
};
