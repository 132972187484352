import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

export function Toggle({ choicesNumberToToggle, choicesCollapsed, onToggle }) {
	const classes = clsx("choices-toggle tag tag-transparent", {
		"choices-toggle--expand": choicesCollapsed,
	});

	return (
		<li className={classes}>
			<button type="button" onClick={onToggle}>
				{`Show ${choicesCollapsed ? `${choicesNumberToToggle} more` : "less"}`}
			</button>
		</li>
	);
}

Toggle.propTypes = {
	choicesNumberToToggle: PropTypes.number.isRequired,
	choicesCollapsed: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};
