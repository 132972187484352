import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSearchResult, isSearchResultLoading } from "./result/selectors";

export const useSearchScrollPosition = (namespace) => {
	const resultIsLoading = useSelector(isSearchResultLoading);
	const resultExists = !!useSelector(getSearchResult);
	const [scrollRestored, setScrollRestored] = useState(false);

	const key = `${namespace}-scroll-position`;

	const saveScrollPosition = (position) => {
		sessionStorage.setItem(key, position);
	};

	const loadScrollPosition = () => {
		return sessionStorage.getItem(key);
	};

	const handleScroll = () => {
		if (resultExists && !resultIsLoading) {
			saveScrollPosition(document.documentElement.scrollTop);
		}
	};

	useEffect(() => {
		if (resultExists && !resultIsLoading && !scrollRestored) {
			const scrollPosition = loadScrollPosition();
			if (scrollPosition) {
				setScrollRestored(true);
				window.setTimeout(() => {
					window.scrollTo({ top: scrollPosition });
				}, 300);
			}

			document.addEventListener("scrollend", handleScroll);
			return () => {
				document.removeEventListener("scrollend", handleScroll);
			};
		}
	}, [resultIsLoading, resultExists]);

	useEffect(() => {
		if ("scrollRestoration" in history) {
			history.scrollRestoration = "manual";
		}
	}, []);

	return { saveScrollPosition, loadScrollPosition };
};
