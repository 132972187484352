/*
 * User Roles.
 */

export const USER_ROLE_ACCIDENTS_AND_INCIDENTS_DOWNLOAD =
	"ACCIDENTS_AND_INCIDENTS_DOWNLOAD";

export const USER_ROLE_ACCIDENTS_AND_INCIDENTS_DOWNLOAD_BIZAV =
	"ACCIDENTS_AND_INCIDENTS_DOWNLOAD_BIZAV";
export const USER_ROLE_AIRLINES = "AIRLINES";
export const USER_ROLE_AIRLINES_BIZAV = "AIRLINES_BIZAV";
export const USER_ROLE_AIRLINES_DOWNLOAD = "AIRLINES_DOWNLOAD";
export const USER_ROLE_AIRLINES_DOWNLOAD_BIZAV = "AIRLINES_DOWNLOAD_BIZAV";
export const USER_ROLE_AIRPORTS = "AIRPORTS";
export const USER_ROLE_CONTACTS = "CONTACTS";
export const USER_ROLE_CONTACTS_DOWNLOAD = "CONTACTS_DOWNLOAD";
export const USER_ROLE_CONTACTS_BIZAV = "CONTACTS_BIZAV";
export const USER_ROLE_CONTACTS_DOWNLOAD_BIZAV = "CONTACTS_DOWNLOAD_BIZAV";
export const USER_ROLE_FLEETS = "FLEETS";
export const USER_ROLE_FLEETS_BIZAV = "FLEETS_BIZAV";
export const USER_ROLE_FLEET_DOWNLOAD = "FLEET_DOWNLOAD";
export const USER_ROLE_FLEET_DOWNLOAD_BIZAV = "FLEET_DOWNLOAD_BIZAV";
export const USER_ROLE_FLEET_HISTORY = "FLEET_HISTORY";
export const USER_ROLE_FLEET_HISTORY_BIZAV = "FLEET_HISTORY_BIZAV";
export const USER_ROLE_FLEET_HISTORY_DOWNLOAD = "FLEET_HISTORY_DOWNLOAD";
export const USER_ROLE_FLEET_HISTORY_DOWNLOAD_BIZAV =
	"FLEET_HISTORY_DOWNLOAD_BIZAV";
export const USER_ROLE_FLEET_IFE_IFC = "FLEET_IFE_IFC";
export const USER_ROLE_FLEET_IFE_IFC_BIZAV = "FLEET_IFE_IFC_BIZAV";
export const USER_ROLE_FLEET_OWNERSHIP = "FLEET_OWNERSHIP";
export const USER_ROLE_FLEET_OWNERSHIP_BIZAV = "FLEET_OWNERSHIP_BIZAV";
export const USER_ROLE_FORECASTING_TYPE_1 = "FORECASTING_TYPE_1";
export const USER_ROLE_HOURS_AND_CYCLES = "HOURS_AND_CYCLES";
export const USER_ROLE_HOURS_AND_CYCLES_BIZAV = "HOURS_AND_CYCLES_BIZAV";
export const USER_ROLE_ORDERS_DOWNLOAD = "ORDERS_DOWNLOAD";
export const USER_ROLE_ORDERS_DOWNLOAD_BIZAV = "ORDERS_DOWNLOAD_BIZAV";
export const USER_ROLE_MRO_DOWNLOAD = "MRO_DOWNLOAD";
export const USER_ROLE_NEWS = "NEWS";
export const USER_ROLE_NEWS_BIZAV = "NEWS_BIZAV";
export const USER_ROLE_NEWS_ALERTS = "NEWS_ALERTS";
export const USER_ROLE_PSS = "PSS";
export const USER_ROLE_SUPERUSER = "SUPER";
export const USER_ROLE = "_USER";
export const USER_ROLE_ADMIN_NEWS_GCMAPCODE = "ADMIN_NEWS_GCMAPCODE";
