export function getDropdownItems(choices, roles) {
	const choicesByRoles = filterChoicesOnRoles(choices, roles);

	return choicesByRoles.map((choice, index) => ({
		key: index,
		value: choice.value,
	}));
}

export function filterChoicesOnRoles(choices, roles) {
	return choices.filter((item) => {
		let hasRequiredRoles = true;
		const requiredRoles = item.requiredRoles?.getShowForRoles();

		if (requiredRoles) {
			hasRequiredRoles = requiredRoles.every((role) =>
				roles.get(role, false),
			);
		}

		return hasRequiredRoles;
	});
}
