import { useDispatch } from "react-redux";
import useModal from "../../common/components/modals/useModal";
import { clearSearch, submitForm } from "../../common/hash/actions";
import { clearInput } from "../../common/input/actions";
import useSelectView from "../views/useSelectView";
import { clearResult } from "./actions";

export default function useSearch() {
	const dispatch = useDispatch();
	const { viewData } = useSelectView();
	const { clearModals } = useModal([]);

	const onSearch = () => {
		dispatch(submitForm(viewData, clearModals));
	};

	const onClearSearch = (e) => {
		dispatch(clearSearch());
		e.target.blur();
	};

	const onClearInput = (e) => {
		dispatch(clearInput());
		e.target.blur();
	};

	const onClearResult = () => {
		dispatch(clearResult());
	};

	return {
		onSearch,
		onClearSearch,
		onClearInput,
		onClearResult,
	};
}
