import { isEmpty } from "lodash";
import { useEffect } from "react";
import useFetchData from "../api/useFetchData";
import useRoleDescriptions from "./useRoleDescriptions";

export default function useFetchRoleDescriptions() {
	const { setDescriptions, roleDescriptions } = useRoleDescriptions();
	const { data, loading, error } = useFetchData({
		endpoint: "/api/v1/public/roles/descriptions",
	});

	useEffect(() => {
		if (data && !error && !loading && isEmpty(roleDescriptions)) {
			setDescriptions(data);
		}
	}, [data, loading, error]);
}
