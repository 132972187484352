import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import useFormComponentState from "../../../common/forms/useFormComponentState";
import { notDeletableType, searchType } from "../../../common/types";
import { ADVANCED_SEARCH, QUICK_SEARCH } from "../../constants";
import useSearchState from "../../useSearchState";
import { CriterionChoice } from "./CriterionChoice";
import { Toggle } from "./Toggle";
import useSelectedChoices from "./useSelectedChoices";

export function CriterionChoices({
	criterionKey,
	notDeletable,
	searchType = QUICK_SEARCH,
}) {
	const { currentInput, onInputChange, onInputChangeAndSearch } =
		useFormComponentState(criterionKey);
	const { resultsAreLoaded } = useSearchState([""]);
	const { choices: selectedChoices } = useSelectedChoices({
		key: criterionKey,
	});
	const { choices, label } = selectedChoices.get(criterionKey).toJS();

	const choicesShown = 5;
	const choicesLength = choices.length;
	const collapseChoices = choicesLength > choicesShown;
	const [choicesCollapsed, setChoicesCollapsed] = useState(collapseChoices);

	useEffect(() => {
		setChoicesCollapsed(collapseChoices);
	}, [collapseChoices]);

	const handleDelete = (...args) => {
		if (searchType === ADVANCED_SEARCH) {
			return onInputChange(...args);
		}

		return resultsAreLoaded
			? onInputChangeAndSearch(...args)
			: onInputChange(...args);
	};

	const criterionChoices = choices.map(({ key, value }, index) => {
		return (
			<CriterionChoice
				key={key}
				choiceKey={key}
				criterionKey={criterionKey}
				currentInput={currentInput}
				hidden={index >= choicesShown && choicesCollapsed}
				notDeletable={notDeletable}
				onDelete={handleDelete}
				value={value}
			/>
		);
	});

	if (choices.length === 0) {
		return null;
	}

	return (
		<>
			<li className="criterion-name tag tag-dark-grey">{label}</li>
			{criterionChoices}
			{collapseChoices && (
				<Toggle
					choicesCollapsed={choicesCollapsed}
					choicesNumberToToggle={choicesLength - choicesShown}
					onToggle={() => setChoicesCollapsed(!choicesCollapsed)}
				/>
			)}
		</>
	);
}

CriterionChoices.propTypes = {
	criterionKey: PropTypes.string.isRequired,
	notDeletable: notDeletableType,
	searchType: searchType,
};
