import PropTypes from "prop-types";
import React from "react";
import { ActionsMenuButton } from "../../../search/actions/ActionsMenuButton";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { Logo } from "../Logo";

export function ModalHeader({ onActionMenuButtonClick }) {
	const { isMobile } = useAccessWindowSize();

	if (!isMobile) {
		return null;
	}

	return (
		<>
			<Logo />
			<ActionsMenuButton
				onClick={onActionMenuButtonClick}
				type="btn--back"
				name="Back"
			/>
		</>
	);
}

ModalHeader.propTypes = {
	onActionMenuButtonClick: PropTypes.func.isRequired,
};
