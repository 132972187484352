import { parseISO } from "date-fns";
import format from "date-fns/format";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import useSearchState from "../../../useSearchState";
import { formatInput, inputToDate, isInputValidDate } from "../range/inputs";
import { DatePickerHeader } from "./DatePickerHeader";

export const DatePickerWrapper = ({
	id,
	filterDate,
	onChange,
	value,
	years,
	criterionKey,
}) => {
	const { currentInput } = useSearchState([criterionKey]);
	const selected = value ? parseISO(value) : "";
	const [input, setInput] = useState(() => formatInput(selected));

	const oppositeSelector = id.endsWith("_from") ? "max" : "min";

	const isDateAvailable = (date) => {
		const oppositeInput = currentInput?.getIn([
			criterionKey,
			oppositeSelector,
		]);
		if (!oppositeInput) {
			return true;
		}
		const currentDate = moment(date);
		const oppositeDate = moment(oppositeInput);

		return oppositeSelector === "max"
			? currentDate.isSameOrBefore(oppositeDate)
			: currentDate.isSameOrAfter(oppositeDate);
	};

	const filterYears = (years) => {
		const oppositeInput = currentInput?.getIn([
			criterionKey,
			oppositeSelector,
		]);
		if (!oppositeInput) {
			return years;
		}
		const oppositeYear = new Date(oppositeInput).getFullYear();

		return years.filter(({ key }) =>
			oppositeSelector === "max"
				? key <= oppositeYear
				: key >= oppositeYear,
		);
	};

	const handleFilterDate = (date) => {
		const dateYear = date.getFullYear();
		const lowestYear = years[0]?.key || dateYear;
		const highestYear = years[years.length - 1]?.key || dateYear;

		const defaultChecks =
			isDateAvailable(date) &&
			dateYear >= lowestYear &&
			dateYear <= highestYear;

		if (filterDate) {
			return filterDate(date) && defaultChecks;
		}
		return defaultChecks;
	};

	useEffect(() => {
		if (value === "") {
			setInput(value);
		}
	}, [value]);

	const handleChange = (e) => {
		const input = e.target.value.toUpperCase();

		setInput(input);

		if (!isInputValidDate(input) || input.length !== 9) {
			return;
		}

		const inputDate = inputToDate(input);

		if (!isDateAvailable(inputDate)) {
			const oppositeInput = currentInput?.getIn([
				criterionKey,
				oppositeSelector,
			]);
			applyDateChange(new Date(oppositeInput));
			return;
		}

		const lowestYear = years[0]?.key;
		const inputDateYear = inputDate.getFullYear();

		if (inputDateYear < lowestYear) {
			applyDateChange(new Date(`${lowestYear}-01-01`));
			return;
		}

		const highestYear = years[years.length - 1]?.key;

		if (inputDateYear > highestYear) {
			applyDateChange(new Date(`${highestYear}-12-31`));
			return;
		}

		onChange(format(inputDate, "yyyy-MM-dd"));
	};

	const applyDateChange = (date) => {
		setInput(formatInput(date));
		onChange(format(date, "yyyy-MM-dd"));
	};

	const handleBlur = () => {
		if (!isInputValidDate(input)) {
			setInput(formatInput(selected));
		}
	};

	const handleOpenToDate = () => {
		if (value) {
			return undefined;
		}
		return new Date();
	};

	return (
		<>
			<InputMask
				type="text"
				id={id}
				name={id}
				value={input}
				mask="99aaa9999"
				maskChar=""
				onChange={handleChange}
				onBlur={handleBlur}
				data-criterion
			/>
			<DatePicker
				calendarStartDay={1}
				dateFormat="yyyy-MM-dd"
				disabledKeyboardNavigation
				data-criterion
				filterDate={handleFilterDate}
				id={id}
				inline
				onChange={applyDateChange}
				renderCustomHeader={(props) => (
					<DatePickerHeader {...props} years={filterYears(years)} />
				)}
				openToDate={handleOpenToDate()}
				selected={selected}
				shouldCloseOnSelect={false}
				showMonthDropdown={true}
				showYearDropdown={true}
			/>
		</>
	);
};

DatePickerWrapper.propTypes = {
	id: PropTypes.string.isRequired,
	filterDate: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	years: PropTypes.array.isRequired,
	criterionKey: PropTypes.string,
};
