import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { BYPASS_BLOCK_ACTIVE_CLASS } from "../../ui/common/bypassBlocks";
import { getFocusableElements } from "../../ui/common/utils";

export function BypassBlock({ label, skipTo }) {
	const [isFocused, setIsFocused] = useState(false);

	const handleClick = (e) => {
		const focusableElements = getFocusableElements(
			document.getElementById(e.target.dataset.block),
		);

		console.log(e.target.dataset.block);

		if (focusableElements) {
			focusableElements[0].focus();
		}
	};

	const handleOnFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<div
			className={clsx("bypass-block", {
				[BYPASS_BLOCK_ACTIVE_CLASS]: isFocused,
			})}
		>
			<button
				type="button"
				data-block={skipTo}
				onFocus={handleOnFocus}
				onBlur={handleBlur}
				onClick={handleClick}
			>
				{label}
			</button>
		</div>
	);
}

BypassBlock.propTypes = {
	label: PropTypes.string.isRequired,
	skipTo: PropTypes.string.isRequired,
};
