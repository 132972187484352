const documentClasses = document.documentElement.classList;
const overlayClass = "overlay--opened";
const fullscreenOverlayClass = "overlay--fullscreen";
const overlayIsOpened = documentClasses.contains(overlayClass);
export const addOverlay = () =>
	!overlayIsOpened && documentClasses.add(overlayClass);
export const addFullscreenOverlay = () =>
	!overlayIsOpened && documentClasses.add(fullscreenOverlayClass);
export const removeOverlay = () => documentClasses.remove(overlayClass);
export const removeFullscreenOverlay = () =>
	documentClasses.remove(fullscreenOverlayClass);
export const setOverlay = (isRelative) =>
	isRelative ? removeOverlay() : addOverlay();
