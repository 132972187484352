import PropTypes from "prop-types";
import React from "react";
import noResultsImage from "../../../../www/static/img/icons/icon-no-search-results.svg";
import {
	additionalSearchLinkType,
	errorType,
	warningsType,
} from "../common/types";
import { Warnings } from "./components/warnings/Warnings";

export function NoSearchResults({
	additionalSearchLink,
	error,
	resultIsInvalid,
	warnings,
}) {
	const getNoResultsContent = () => {
		if (error || resultIsInvalid) {
			return (
				<>
					<h5>{error ? error.message : "Search request failed"}</h5>
					<p>
						Please change your search parameters or contact us on{" "}
						<a href="mailto: support@ch-aviation.com">
							support@ch-aviation.com
						</a>
						.
					</p>
				</>
			);
		}

		if (additionalSearchLink) {
			return additionalSearchLink;
		}

		return (
			<>
				<h5>No results</h5>
				<p>
					There are no results at the moment. <br />
					Start searching by filter.
				</p>
			</>
		);
	};

	return (
		<>
			<Warnings warnings={warnings} />
			<div className="no-search-results-wrapper">
				<div className="no-search-results grid">
					<img src={noResultsImage} alt="No results" />
					{getNoResultsContent()}
				</div>
			</div>
		</>
	);
}

NoSearchResults.propTypes = {
	additionalSearchLink: additionalSearchLinkType,
	error: errorType,
	resultIsInvalid: PropTypes.bool.isRequired,
	warnings: warningsType,
};
