import { namespaceType } from "../common/types";
import { useSearchScrollPosition } from "./useSearchScrollPosition";

export const SearchScrollPositionInitializer = ({ namespace }) => {
	useSearchScrollPosition(namespace);

	return null;
};

SearchScrollPositionInitializer.propTypes = {
	namespace: namespaceType.isRequired,
};
