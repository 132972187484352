import Immutable from "immutable";
import { ROLES_SET_ROLE_DESCRIPTIONS, ROLES_INIT } from "./actions";

export function createRolesReducer(roles) {
	const initialState = Immutable.fromJS({
		roles: roles || {},
		roleDescriptions: {},
	});

	return function (state = initialState, action) {
		switch (action.type) {
			case ROLES_INIT:
				return state.merge({
					roles: action.data.roles,
				});
			case ROLES_SET_ROLE_DESCRIPTIONS:
				return state.merge({
					roleDescriptions: action.data.roleDescriptions,
				});
			default:
				return state;
		}
	};
}
