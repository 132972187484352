import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import useFormComponentState from "../../../../common/forms/useFormComponentState";
import {
	requiredRolesType,
	visibilitySelectorType,
} from "../../../../common/types";
import { SearchCriterion } from "../common/SearchCriterion";
import { createDatePicker, createYearPicker, getInputRange } from "./inputs";
import { Range } from "./Range";

export function RangeCriterion({
	createControl,
	criterionKey,
	label,
	prefix,
	quickSearch = false,
	requiredRoles,
	visibilitySelector,
	...props
}) {
	const { currentInput, onInputChange, onClearInput } = useFormComponentState(
		criterionKey,
		Immutable.Map(),
	);

	const datesRange = createControl === createDatePicker;
	const yearsRange = createControl === createYearPicker;
	const inputMin = currentInput.get("min", "");
	const inputMax = currentInput.get("max", "");
	const inputRange = getInputRange(inputMin, inputMax, datesRange);

	return (
		<SearchCriterion
			label={label}
			prefix={prefix}
			quickSearch={quickSearch}
			requiredRoles={requiredRoles}
			visibilitySelector={visibilitySelector}
		>
			<Range
				createControl={createControl}
				criterionKey={criterionKey}
				currentInput={currentInput}
				datesRange={datesRange}
				label={label}
				inputMax={inputMax}
				inputMin={inputMin}
				inputRange={inputRange}
				onClearInput={onClearInput}
				onInputChange={onInputChange}
				prefix={prefix}
				quickSearch={quickSearch}
				yearsRange={yearsRange}
				{...props}
			/>
		</SearchCriterion>
	);
}

RangeCriterion.propTypes = {
	createControl: PropTypes.func,
	criterionKey: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	prefix: PropTypes.string,
	quickSearch: PropTypes.bool,
	requiredRoles: requiredRolesType,
	visibilitySelector: visibilitySelectorType,
};
