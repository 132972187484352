import PropTypes from "prop-types";
import React from "react";

export function CloseButton({
	classes = "btn btn--primary",
	label = "Close",
	onClick,
}) {
	return (
		<button type="button" className={classes} onClick={onClick}>
			{label}
		</button>
	);
}

CloseButton.propTypes = {
	classes: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};
