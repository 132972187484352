import PropTypes from "prop-types";
import React from "react";
import useModal from "../../common/components/modals/useModal";
import {
	criteriaType,
	customizeQuickSearchType,
	faqType,
	namespaceType,
	notDeletableType,
} from "../../common/types";
import { useLocalSearchView } from "../../common/useLocalSearchView";
import { QuickSearch } from "../criteria/quick-search/QuickSearch";
import { useAccessQuickSearchCriteria } from "../criteria/quick-search/useAccessQuickSearchCriteria";
import { mapSearchCriteria, SearchType } from "../searchCriteriaMapper";
import { BypassBlock } from "./BypassBlock";
import { QuickSearchCriteriaInitializer } from "./QuickSearchCriteriaInitializer";
import { SearchButtons } from "./SearchButtons";

export function SearchHeader({
	criteria,
	customizeQuickSearch,
	faq,
	namespace,
	notDeletable,
	onSearchClick,
	onClearSearchClick,
}) {
	const { onCloseModal } = useModal();
	const { quickSearchCriteriaLoading, quickSearchCriteria } =
		useAccessQuickSearchCriteria(criteria.quickSearchFallbackCriteria);
	const mappedCriteria = quickSearchCriteria
		? mapSearchCriteria(
				quickSearchCriteria,
				SearchType.QUICK_SEARCH,
				criteria.mapSearchCriterion,
				undefined,
				undefined,
			)
		: [];
	const noCriteriaLoaded = mappedCriteria.length === 0;
	const { clearSearchView } = useLocalSearchView(namespace);

	if (noCriteriaLoaded) {
		return null;
	}

	const handleClearSearchClick = (e) => {
		onClearSearchClick(e);
		onCloseModal();
		clearSearchView();
	};

	return (
		<div className="search-types">
			<QuickSearchCriteriaInitializer
				namespace={namespace}
				criteria={criteria}
				customizeQuickSearch={customizeQuickSearch}
			/>
			<BypassBlock
				skipTo="searchResults"
				label="Skip to search results"
			/>
			<SearchButtons
				customizeQuickSearchEnabled={customizeQuickSearch.enabled}
				faq={faq}
				onClearSearchClick={handleClearSearchClick}
			/>
			<QuickSearch
				criteria={mappedCriteria}
				notDeletable={notDeletable}
				onSearchClick={onSearchClick}
				onClearSearchClick={handleClearSearchClick}
				loading={quickSearchCriteriaLoading}
			/>
		</div>
	);
}

SearchHeader.propTypes = {
	criteria: criteriaType.isRequired,
	customizeQuickSearch: customizeQuickSearchType.isRequired,
	faq: faqType,
	namespace: namespaceType.isRequired,
	notDeletable: notDeletableType,
	onSearchClick: PropTypes.func.isRequired,
	onClearSearchClick: PropTypes.func.isRequired,
};
