import { useRef, useState } from "react";
import { useCloseDropdownEvents } from "../useCloseDropdownEvents";

export function useDropdown(closeOnClickOutside) {
	const [dropdownIsOpened, setDropdownIsOpened] = useState(false);
	const dropdownRef = useRef(null);

	useCloseDropdownEvents(
		dropdownRef,
		dropdownIsOpened,
		() => setDropdownIsOpened(!dropdownIsOpened),
		closeOnClickOutside,
	);

	const onDropdownToggle = () => {
		setDropdownIsOpened(!dropdownIsOpened);
	};

	const onDropdownItemWasSelected = () => {
		dropdownRef.current.setAttribute("data-selected", true);
	};

	return {
		dropdownRef,
		dropdownIsOpened,
		onDropdownToggle,
		onDropdownItemWasSelected,
	};
}
