import * as _ from "lodash";
import { SearchGroup } from "./criteria/searchGroup";

export const SearchType = {
	QUICK_SEARCH: "quick-search",
	FULL_SEARCH: "full-search",
};

export const mapSearchCriteria = (
	criteriaDefinition,
	searchType,
	mapCriterion,
	criteriaValidation,
	groupVisibilitySelector,
	groupRequiredRoles,
) => {
	const criteria = [];

	switch (searchType) {
		case SearchType.QUICK_SEARCH:
			for (const criterion of criteriaDefinition) {
				criteria.push(mapCriterion(criterion, searchType));
			}
			break;

		case SearchType.FULL_SEARCH:
			for (const [groupName, criteriaList] of Object.entries(
				criteriaDefinition,
			)) {
				const group = new SearchGroup(
					groupName,
					_.camelCase(groupName),
					groupVisibilitySelector[groupName],
					groupRequiredRoles[groupName],
				);
				for (const criterion of criteriaList) {
					group.add(
						criterion,
						(key) => mapCriterion(key, searchType),
						criteriaValidation[criterion],
					);
				}
				criteria.push(group);
			}
			break;

		default:
			// eslint-disable-next-line no-console
			console.log(`Unknown search type ${searchType}`);
			break;
	}

	return criteria;
};
