import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { addModal, deleteModal, resetModals } from "./actions";
import {
	MENU_CRITERION,
	MENU_DOWNLOADS_OPTIONS,
	MENU_FAVORITES_OPTIONS,
	MODAL_ADVANCED_SEARCH,
	MODAL_CUSTOMIZE_QUICK_SEARCH,
	MODAL_EDIT_VIEW,
} from "./constants";
import { getOpenedModals } from "./reducers";
import { addFullscreenOverlay, removeFullscreenOverlay, removeOverlay, setOverlay } from "./setOverlay";

/**
 * @param {string[]|null} [specifiers] - a list of modal ids, if provided, will listen only for changes on the given ids
 */
export default function useModal(specifiers) {
	const dispatch = useDispatch();
	const openedModals = useSelector(getOpenedModals, (prev, curr) =>
		specifiers
			? specifiers.every(
					(specifier) =>
						prev.includes(specifier) === curr.includes(specifier),
				)
			: shallowEqual(prev, curr),
	);
	const openedModalsExist = !openedModals.isEmpty();
	const { isMobile } = useAccessWindowSize();

	const onOpenModal = (key, isRelative = false) => {
		if (
			!isMobile &&
			(key.includes(MENU_CRITERION) ||
				key.includes(MENU_DOWNLOADS_OPTIONS) ||
				key.includes(MENU_FAVORITES_OPTIONS))
		) {
			return;
		}

		const overlays = [
			MODAL_ADVANCED_SEARCH,
			MODAL_EDIT_VIEW,
			MODAL_CUSTOMIZE_QUICK_SEARCH,
		];

		setOverlay(isRelative);

		if (overlays.includes(key)) {
			addFullscreenOverlay();
		}

		if (overlays.includes(key) || !isMobile) {
			dispatch(resetModals());
		}

		dispatch(addModal(key));
	};

	const onCloseModal = (key) => {
		// If this is the only opened modal, remove overlay
		openedModals.size === 1 &&
			openedModals.includes(key) &&
			removeOverlay() &&
			removeFullscreenOverlay();
		dispatch(deleteModal(key));
	};

	const clearModals = () => {
		removeOverlay();
		removeFullscreenOverlay();
		dispatch(resetModals());
	};

	return {
		openedModals,
		openedModalsExist,
		onOpenModal,
		onCloseModal,
		clearModals,
	};
}
