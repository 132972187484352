import { useCallback, useEffect } from "react";

export function useCloseDropdownEvents(
	ref,
	isOpened,
	onClose,
	closeOnClickOutside = true,
	ignoreKeyUp = false,
) {
	const handleFocusOut = useCallback(
		(e) => {
			const focusIsOutside =
				ref.current && !ref.current.contains(e.target);
			if (focusIsOutside || e.key === "Escape") {
				onClose();
			}
		},
		[onClose],
	);

	useEffect(() => {
		if (closeOnClickOutside && isOpened) {
			document.addEventListener("mousedown", handleFocusOut);
			if (!ignoreKeyUp) {
				document.addEventListener("keyup", handleFocusOut);
			}

			return () => {
				document.removeEventListener("mousedown", handleFocusOut);
				if (!ignoreKeyUp) {
					document.removeEventListener("keyup", handleFocusOut);
				}
			};
		}
	}, [closeOnClickOutside, isOpened]);
}
