import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { childrenType } from "../../types";
import { CloseButton } from "./CloseButton";
import { ModalHeader } from "./ModalHeader";
import useKeyboardNavigation from "./useKeyboardNavigation";
import useModal from "./useModal";

export function Modal({
	additionalClasses,
	alignContent,
	modalKey,
	title,
	children,
}) {
	const modalRef = useRef(null);
	const { openedModalsExist, onCloseModal } = useModal();
	useKeyboardNavigation(
		() => onCloseModal(modalKey),
		modalRef,
		openedModalsExist,
	);

	const classes = clsx("modal", additionalClasses, {
		open: openedModalsExist,
	});

	return (
		<div
			role="dialog"
			className={classes}
			aria-labelledby="modalTitle"
			aria-modal="true"
			ref={modalRef}
			onClick={() => onCloseModal(modalKey)} // To close modal on click outside
		>
			<div
				className={clsx("modal__container", alignContent)}
				onClick={(e) => e.stopPropagation()} // To not close modal on click inside
			>
				<header className="modal__header">
					<ModalHeader
						onActionMenuButtonClick={() => onCloseModal(modalKey)}
					/>
					<h2 id="modalTitle" className="modal__title">
						{title}
					</h2>
					<CloseButton
						label={`Close ${title} modal`}
						classes="btn--close"
						onClick={() => onCloseModal(modalKey)}
					/>
				</header>

				{children}
			</div>
		</div>
	);
}

Modal.propTypes = {
	additionalClasses: PropTypes.string,
	alignContent: PropTypes.string,
	children: childrenType.isRequired,
	modalKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};
