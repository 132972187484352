import Immutable from "immutable";
import { useDispatch, useSelector } from "react-redux";
import { setRoleDescriptions } from "./actions";
import { getRoleDescriptions } from "./selectors";

export default function useRoleDescriptions() {
	const dispatch = useDispatch();
	const roleDescriptions = useSelector(getRoleDescriptions).toJS();

	const setDescriptions = (roleDescriptions) =>
		dispatch(setRoleDescriptions(Immutable.fromJS(roleDescriptions)));

	return {
		roleDescriptions,
		setDescriptions,
	};
}
