import Immutable from "immutable";
import {
	USER_ROLE_NEWS,
	USER_ROLE_NEWS_BIZAV,
} from "../common/roles/constants";
import { getInputFromClientState } from "../search/criteria/utils";

export const newsViewOptions = [
	{ key: "summary", value: "Summary view" },
	{ key: "full", value: "Full view" },
];

export const newsSegments = {
	BZA: "BZA",
	CMC: "CMC",
};

export const newsTypes = {
	TYPE_GENERAL: "typeGeneral",
	TYPE_ROUTE: "typeRoute",
	TYPE_FLEETS: "typeFleets",
	TYPE_PEOPLE: "typePeople",
};

export const newsTypeChoices = [
	{ key: newsTypes.TYPE_GENERAL, value: "News" },
	{ key: newsTypes.TYPE_ROUTE, value: "Route Network Changes" },
	{ key: newsTypes.TYPE_FLEETS, value: "Fleet News" },
	{ key: newsTypes.TYPE_PEOPLE, value: "Management Changes" },
];

export const newsSearchBy = [
	{ key: "airlineSearches", value: "Operator Search" },
	{ key: "listFavourites", value: "List/Favourites" },
];

export const newsDisplay =
	getInputFromClientState("display", "cha-news-display-", newsViewOptions) ||
	"summary";

export const getNewsType = (roles) => {
	if (
		!!(roles[USER_ROLE_NEWS] && roles[USER_ROLE_NEWS_BIZAV]) ||
		(!roles[USER_ROLE_NEWS] && !roles[USER_ROLE_NEWS_BIZAV])
	) {
		return { news_type: [newsTypes.TYPE_GENERAL, newsTypes.TYPE_FLEETS] };
	}

	if (!!roles[USER_ROLE_NEWS] && !roles[USER_ROLE_NEWS_BIZAV]) {
		return {
			news_type: [newsTypes.TYPE_GENERAL, newsTypes.TYPE_FLEETS],
			segments: [newsSegments.CMC],
		};
	}

	if (!roles[USER_ROLE_NEWS] && !!roles[USER_ROLE_NEWS_BIZAV]) {
		return {
			news_type: [newsTypes.TYPE_GENERAL, newsTypes.TYPE_FLEETS],
			segments: [newsSegments.BZA],
		};
	}
};

export const getDefaultNewsInput = (roles) => {
	let defaultInput = Immutable.fromJS({
		display: newsDisplay,
		news_search_by: "airlineSearches",
		...getNewsType(roles),
	});

	const isFreeOrAnonymousUser =
		!roles[USER_ROLE_NEWS] && !roles[USER_ROLE_NEWS_BIZAV];

	if (!roles[USER_ROLE_NEWS] || isFreeOrAnonymousUser) {
		defaultInput = defaultInput.set("display", newsViewOptions[0].key);
	}

	return defaultInput;
};
