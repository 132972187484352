export function getChoices(state) {
	return state.getIn(["search", "criteria", "choices"]);
}

export function getChoiceLabels(state) {
	return getChoices(state).keySeq().toList();
}

export function getCriterionChoices(state, criterionKey) {
	return state.getIn([
		"search",
		"criteria",
		"choices",
		criterionKey,
		"choices",
	]);
}

export function getCriterionChoicesAreLoading(state, criterionKey) {
	return state.getIn([
		"search",
		"criteria",
		"choices",
		criterionKey,
		"loading",
	]);
}

export function getCriterionChoicesError(state, criterionKey) {
	return state.getIn([
		"search",
		"criteria",
		"choices",
		criterionKey,
		"error",
	]);
}

export function getCriterionYears(state, criterionKey) {
	const dates = state.getIn(["search", "criteria", "years"]);
	return dates.find((item) => item.key === `${criterionKey}_least`)?.value;
}
