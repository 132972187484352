import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { sendForm } from "../../api/sendForm";
import { CloseButton } from "../../components/modals/CloseButton";
import { MODAL_REQUEST_SUCCESS } from "../../components/modals/constants";
import { Modal } from "../../components/modals/Modal";
import useModal from "../../components/modals/useModal";
import useFetchUserData from "../../user/useFetchUserData";
import { EmailField } from "../components/EmailField";
import { TextareaField } from "../components/TextareaField";
import { TextField } from "../components/TextField";

export function DataDownloadRequest({ modalKey }) {
	const { onOpenModal, onCloseModal } = useModal();
	const { userData, loading } = useFetchUserData();

	const defaultValues = userData
		? {
				first_name: userData.firstName,
				last_name: userData.lastName,
				email: userData.email,
				company: userData.company,
				job_title: userData.jobTitle,
				extract_type: "Aircraft Flight Tracking Data",
			}
		: { extract_type: "Aircraft Flight Tracking Data" };

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({ defaultValues });
	const [error, setError] = useState(undefined);
	const errorsCount = Object.keys(errors).length;

	useEffect(() => {
		reset(defaultValues);
	}, [userData]);

	useEffect(() => {
		errorsCount > 0
			? setError("Please fill all the required fields.")
			: null;
	}, [errorsCount]);

	const onSubmit = (data) => {
		const successHandler = () => {
			onOpenModal(MODAL_REQUEST_SUCCESS);
			setError(undefined);
		};

		const failureHandler = (error) => {
			setError(error.message);
		};

		sendForm(
			data,
			"/api/v1/data_download_requests",
			successHandler,
			failureHandler,
		);
	};

	const title = "Aircraft Flight Tracking Data Download Request";
	const additionalClasses = "modal--relative";

	return (
		<Modal
			additionalClasses={additionalClasses}
			title={title}
			modalKey={modalKey}
		>
			<div className="modal__content">
				<div className="modal__body">
					<form
						id="data-download-request-form"
						onSubmit={handleSubmit(onSubmit)}
						aria-label={title}
					>
						<section>
							<TextField
								name="extract_type"
								id="data-download-request-extract-type"
								label="Extract Type"
								disabled
								required
								register={register}
								errors={errors}
							/>

							<TextField
								name="first_name"
								id="data-download-request-first-name"
								label="First Name"
								placeholder={
									loading ? "Loading..." : "First Name"
								}
								required
								register={register}
								errors={errors}
							/>

							<TextField
								name="last_name"
								id="data-download-request-last-name"
								label="Last Name"
								placeholder={
									loading ? "Loading..." : "Last Name"
								}
								required
								register={register}
								errors={errors}
							/>

							<EmailField
								name="email"
								id="data-download-request-email"
								label="Email"
								placeholder={
									loading ? "Loading..." : "Your Email"
								}
								required
								register={register}
								errors={errors}
							/>

							<TextField
								name="company"
								id="data-download-request-company"
								label="Company"
								placeholder={
									loading ? "Loading..." : "Company name"
								}
								required
								autoComplete="organization"
								register={register}
								errors={errors}
							/>

							<TextField
								name="job_title"
								id="data-download-request-job-title"
								label="Job Title"
								placeholder={
									loading ? "Loading..." : "Job Title"
								}
								required
								autoComplete="organization"
								register={register}
								errors={errors}
							/>

							<TextareaField
								id="comments"
								name="comments"
								label="Comments"
								required
								register={register}
								errors={errors}
							/>
						</section>
					</form>
				</div>
			</div>

			<footer className={clsx(`flex modal__actions`, { error })}>
				{error && (
					<span className="error-text" role="alert">
						{error}
					</span>
				)}
				<CloseButton
					classes="btn btn--secondary"
					onClick={() => onCloseModal(modalKey)}
					label="Cancel"
				/>
				<button
					type="submit"
					className="btn btn--primary"
					form="data-download-request-form"
				>
					Send request
				</button>
			</footer>
		</Modal>
	);
}

DataDownloadRequest.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: PropTypes.string.isRequired,
};
