import Immutable from "immutable";
import { RESET_SEARCH_DATA } from "../../hash/actions";
import { MODAL_CLEAR_ALL, MODAL_CLOSE, MODAL_OPEN } from "./actions";

const initialState = Immutable.List();

export default function (state = initialState, action) {
	switch (action.type) {
		case MODAL_OPEN:
			return state.push(action.data.key);
		case MODAL_CLOSE: {
			const key = action.data.key;

			if (key) {
				return state.includes(key)
					? state.delete(state.indexOf(key))
					: state;
			} else {
				return state.pop();
			}
		}
		case RESET_SEARCH_DATA:
		case MODAL_CLEAR_ALL:
			return initialState;
		default:
			return state;
	}
}

export function getOpenedModals(state) {
	return state.get("modals");
}
