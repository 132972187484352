export const ROLES_INIT = "ROLES_INIT";
export const ROLES_SET_ROLE_DESCRIPTIONS = "ROLES_SET_ROLE_DESCRIPTIONS";

export function initRoles(roles) {
	return {
		type: ROLES_INIT,
		data: {
			roles,
		},
	};
}

export function setRoleDescriptions(roleDescriptions) {
	return {
		type: ROLES_SET_ROLE_DESCRIPTIONS,
		data: {
			roleDescriptions,
		},
	};
}
