import PropTypes from "prop-types";
import React from "react";
import { CloseButton } from "../components/modals/CloseButton";
import { Modal } from "../components/modals/Modal";
import useModal from "../components/modals/useModal";

export function ModalSuccess({ modalKey, title, content }) {
	const { onCloseModal } = useModal();

	const alignContent = "modal__container--center";
	const additionalClasses = "modal--success";

	return (
		<Modal
			modalKey={modalKey}
			title={title}
			alignContent={alignContent}
			additionalClasses={additionalClasses}
		>
			{content && (
				<div className="flex justify-center modal__content">
					<p>{content}</p>
				</div>
			)}

			<footer className="flex modal__actions">
				<CloseButton onClick={() => onCloseModal(modalKey)} />
			</footer>
		</Modal>
	);
}

ModalSuccess.propTypes = {
	modalKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
};
