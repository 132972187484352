import React from "react";
import { disclaimersType } from "../../../common/types";
import useSearchState from "../../useSearchState";

export function DisclaimersContainer({ disclaimers }) {
	if (!disclaimers) {
		return null;
	}

	const { columnsVisible, currentInput, result } = useSearchState([""]);

	disclaimers.clearDisclaimers();
	disclaimers.checkResult(result);
	disclaimers.checkColumns(columnsVisible);
	disclaimers.checkInput(currentInput);

	if (!disclaimers.hasDisclaimers()) {
		return null;
	}

	return (
		<div className="disclaimers">
			{disclaimers.toArray().map((disclaimer) => {
				return (
					<div key={disclaimer.key} className="disclaimer">
						{disclaimer.description}
					</div>
				);
			})}
		</div>
	);
}

DisclaimersContainer.propTypes = {
	disclaimers: disclaimersType,
};
