import { waitForElement } from "../../../ui/common/waitForElement";

export function setScrollerPositioning() {
	const horizontalScrollSelector = ".results-scroller-track-x";

	waitForElement(horizontalScrollSelector).then(() => {
		const horizontalScroll = document.querySelector(
			horizontalScrollSelector,
		);
		const searchResults = horizontalScroll?.closest(".results");
		const horizontalScrollVisible =
			document.querySelector(horizontalScrollSelector)?.style.display !==
			"none";

		if (horizontalScrollVisible && searchResults) {
			let stickScrollOn = getStickOnScrollPosition(searchResults);
			horizontalScroll.style.position = "fixed";

			window.addEventListener("resize", () => {
				stickScrollOn = getStickOnScrollPosition(searchResults);
			});

			window.addEventListener("scroll", () => {
				const currentScroll = window.scrollY;

				if (
					horizontalScrollVisible &&
					isScrolledIntoView(searchResults)
				) {
					horizontalScroll.style.display = "block";
				} else {
					horizontalScroll.style.display = "none";
				}

				// Stick scroller to the bottom of the table once scrolled there
				if (currentScroll >= stickScrollOn) {
					horizontalScroll.style.position = "absolute";
					horizontalScroll.style.width = "100%";
				} else {
					horizontalScroll.style.position = "fixed";
					horizontalScroll.style.width = "90%";
				}
			});
		}

		function isScrolledIntoView(el) {
			const rect = el.getBoundingClientRect();

			return rect.top < window.innerHeight && rect.bottom >= 0;
		}

		function getStickOnScrollPosition(results) {
			return (
				results.getBoundingClientRect().top +
				window.scrollY +
				results.offsetHeight -
				window.innerHeight
			);
		}
	});
}
