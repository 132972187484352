import PropTypes from "prop-types";
import React from "react";

export function Accordion({ children }) {
	return <div className="accordion">{children}</div>;
}

Accordion.propTypes = {
	children: PropTypes.node.isRequired,
};
