import { useSelector } from "react-redux";
import { getChoices } from "../selectors";
import { isEqual } from "lodash";

/**
 * @param {boolean|null} [ignoreContent] - should the hook ignore the content of selected choices
 * @param {string|null} [key] - allows the hook to subscribe to only one criterion
 */
export default function useSelectedChoices({
	ignoreContent = false,
	key = null,
}) {
	const choices = useSelector(getChoices, (left, right) => {
		if (ignoreContent) {
			return left.keySeq().toList().equals(right.keySeq().toList());
		}
		if (key) {
			return isEqual(
				left.get(key).get("choices"),
				right.get(key).get("choices"),
			);
		}
		return left.equals(right);
	});

	return { choices, choicesEmpty: choices.isEmpty() };
}
