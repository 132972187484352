import PropTypes from "prop-types";
import React from "react";
import { getCriteriaKeys, sortChoices } from "./choices";
import { CriterionChoices } from "./CriterionChoices";
import useSelectedChoices from "./useSelectedChoices";

export function SelectedChoices({ groupCriteria, ...props }) {
	const { choices, choicesEmpty } = useSelectedChoices({
		ignoreContent: true,
	});
	let selectedChoices = sortChoices(choices);

	if (choicesEmpty) {
		return null;
	}

	if (groupCriteria) {
		const criteria = getCriteriaKeys(groupCriteria);
		choices.filter((v, key) => criteria.includes(key));
		selectedChoices = sortChoices(choices).filter((v, key) =>
			criteria.includes(key),
		);
	}

	if (selectedChoices.size === 0) {
		return null;
	}

	const labels = selectedChoices.keySeq().map((key) => {
		return <CriterionChoices key={key} criterionKey={key} {...props} />;
	});

	return <ul className="search-selected-choices">{labels}</ul>;
}

SelectedChoices.propTypes = {
	groupCriteria: PropTypes.object,
};
