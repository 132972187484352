import { BACKDROP, BACKDROP_OVERLAY, OVERLAY } from "../constants";
import { waitForElement } from "./waitForElement";

export const showElement = (element, display = "block") => {
	element.style.display = display;
};

export const getFocusableElements = (section) => {
	return section?.querySelectorAll(
		'button:not([tabindex^="-"]), [href]:not([tabindex^="-"]), input:not([tabindex^="-"]), textarea:not([tabindex^="-"]), [tabindex]:not([tabindex^="-"])',
	);
};

export const addOverlay = () => document.documentElement.classList.add(OVERLAY);

export const addBackdrop = () =>
	document.documentElement.classList.add(BACKDROP);

export const addBackdropOverlay = () =>
	document.documentElement.classList.add(BACKDROP_OVERLAY);

export const removeOverlay = () =>
	document.documentElement.classList.remove(OVERLAY);

export const removeBackdrop = () =>
	document.documentElement.classList.remove(BACKDROP);

export const removeBackdropOverlay = () =>
	document.documentElement.classList.remove(BACKDROP_OVERLAY);

export function waitForCookiesBanner(onReady, onClose = () => {}) {
	waitForElement("#main-cookie-banner").then(() => {
		const cookiesBanner = document.getElementById("main-cookie-banner");
		const dependentItemHeight = cookiesBanner.style.height;

		if (dependentItemHeight === "") {
			const observer = new MutationObserver((mutations) => {
				mutations.forEach(() => {
					const elementHeight = cookiesBanner.style.height;

					if (elementHeight !== "") {
						onReady(parseInt(elementHeight, 10));
					}

					if (cookiesBanner.style.display === "none") {
						onClose();
					}
				});
			});

			observer.observe(cookiesBanner, {
				attributes: true,
				attributeFilter: ["style"],
			});
		} else {
			onReady(parseInt(dependentItemHeight, 10));
		}
	});
}
