import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCounts } from "../api";
import useSearchState from "../useSearchState";
import {
	receivePreview,
	receiveRequestPreviewFailed,
	requestPreview,
} from "./actions";
import { shouldLoadPreview } from "./selectors";

export default function useLoadResultsPreview(namespace, isPrimary = true) {
	const dispatch = useDispatch();
	const shouldLoadCounts = useSelector(shouldLoadPreview);
	const { currentInput } = useSearchState();

	useEffect(() => {
		if (shouldLoadCounts && isPrimary) {
			loadPreview();
		}
	}, [shouldLoadCounts]);

	let previewTimeoutId = null;

	function loadPreview() {
		if (previewTimeoutId === null) {
			dispatch(requestPreview());

			previewTimeoutId = window.setTimeout(() => {
				loadCounts(
					namespace,
					currentInput,
					(data) => {
						window.clearTimeout(previewTimeoutId);
						previewTimeoutId = null;

						dispatch(receivePreview(data.paging));
					},
					(error) => {
						dispatch(receiveRequestPreviewFailed(error));
					},
				);
			}, 1000);
		}
	}
}
