import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { AccordionItem } from "../../../common/components/accordion/AccordionItem";
import useRolesVisibility from "../../../common/roles/useRolesVisibility";
import { columnsType } from "../../../common/types";
import useSelectAll from "../../criteria/components/useSelectAll";

export function ColumnsGroup({
	columns,
	mandatoryColumns,
	onInputChange,
	selection,
}) {
	const { allChecked, onSelectAll } = useSelectAll(
		columns,
		selection,
		onInputChange,
		mandatoryColumns,
	);
	const { id, rolesBasedTitle, title } =
		columns.length > 0 ? columns[0].group : {};
	const { hasRequiredRoles } = useRolesVisibility(
		rolesBasedTitle?.requiredRoles,
	);

	if (columns.length === 0) {
		return null;
	}

	const label =
		rolesBasedTitle && hasRequiredRoles ? rolesBasedTitle.title : title;

	const handleToggle = (key) => {
		const newInput = selection.includes(key)
			? selection.delete(key)
			: selection.add(key);
		onInputChange(newInput);
	};

	const choices = columns.map((column) => {
		const { key, label } = column;
		const checkboxKey = `${key}ColumnCheckbox`;

		return (
			<div
				key={key}
				className="grid__col-12 grid__col-md-6 grid__col-lg-4 grid__col-xl-3"
			>
				<div className="checkbox-input">
					<input
						type="checkbox"
						id={checkboxKey}
						disabled={mandatoryColumns.includes(key)}
						name={key}
						value={key}
						checked={selection.includes(key)}
						onChange={() => handleToggle(key)}
					/>
					<label htmlFor={checkboxKey}>{label}</label>
				</div>
			</div>
		);
	});

	const selectAllKey = `${id}-select-all`;
	const accordionHeaderContent = (
		<div className="group-select-all checkbox-input">
			<input
				type="checkbox"
				id={selectAllKey}
				name={selectAllKey}
				value={selectAllKey}
				checked={allChecked}
				onChange={(e) => onSelectAll(e.target.checked)}
			/>
			<label htmlFor={selectAllKey}>Select all</label>
		</div>
	);

	return (
		<AccordionItem
			accordionKey={id}
			label={label}
			headerContent={accordionHeaderContent}
		>
			{choices}
		</AccordionItem>
	);
}

ColumnsGroup.propTypes = {
	columns: columnsType.isRequired,
	mandatoryColumns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	onInputChange: PropTypes.func.isRequired,
	selection: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
};
