import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import useRoles from "../../../../common/roles/useRoles";
import {
	criteriaGroupType,
	quickSearchInputType,
} from "../../../../common/types";
import { filterChoicesOnRoles } from "../../components/dropdown/choices";
import RadioButtons from "../../components/radio/RadioButtons";

export function CustomizeQuickSearchRadio({
	group,
	input,
	onInputChange,
	radioKey,
}) {
	const { roles } = useRoles();

	if (!radioKey) {
		return null;
	}

	const criterion = group.criteria[radioKey].factory(radioKey).props;
	const radioLabel = criterion.label;
	const radioChoices = filterChoicesOnRoles(criterion.choices, roles);
	const defaultChoice = radioChoices[0].key;
	const radioInput = input?.get(radioKey);

	useEffect(() => {
		if (!radioInput) {
			onInputChange(
				input
					? input.set(radioKey, defaultChoice)
					: Immutable.Map({ [radioKey]: defaultChoice }),
			);
		}
	}, [input, radioInput]);

	const handleInputChange = (key) => onInputChange(input.set(radioKey, key));

	return (
		<div className="radio-group grid__col-12 grid__col-md-6 grid__col-xl-3">
			<RadioButtons
				currentInput={radioInput || defaultChoice}
				onInputChange={handleInputChange}
				label={radioLabel}
				criterionKey={radioKey}
				choices={radioChoices}
			/>
		</div>
	);
}

CustomizeQuickSearchRadio.propTypes = {
	group: criteriaGroupType.isRequired,
	input: quickSearchInputType,
	onInputChange: PropTypes.func.isRequired,
	radioKey: PropTypes.string,
};
