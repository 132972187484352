import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useAccessWindowSize } from "../../layout/useAccessWindowSize";
import { childrenType, searchInputType } from "../../types";
import { Logo } from "../Logo";
import { CloseButton } from "./CloseButton";
import { SearchInput } from "./SearchInput";
import useKeyboardNavigation from "./useKeyboardNavigation";
import useModal from "./useModal";

export function Overlay({
	additionalClasses,
	description,
	title,
	searchInput,
	children,
}) {
	const modalRef = useRef(null);
	const { openedModalsExist, clearModals } = useModal();
	useKeyboardNavigation(clearModals, modalRef, openedModalsExist);
	const { isMobile } = useAccessWindowSize();

	const classes = clsx("overlay", additionalClasses, {
		open: openedModalsExist,
	});

	return (
		<div
			role="dialog"
			className={classes}
			aria-labelledby="modalTitle"
			aria-modal="true"
			ref={modalRef}
		>
			<div className="modal__container">
				<header
					className={clsx("modal__header flex", {
						"modal__header--with-search": searchInput,
					})}
				>
					{isMobile && <Logo />}
					<div className="modal__title">
						<h2 id="modalTitle">{title}</h2>
						{description && (
							<p className="modal__description">{description}</p>
						)}
					</div>

					<SearchInput searchInput={searchInput} />

					<CloseButton
						label={`Close ${title} modal`}
						classes="btn--close"
						onClick={clearModals}
					/>
				</header>

				{children}
			</div>
		</div>
	);
}

Overlay.propTypes = {
	additionalClasses: PropTypes.string,
	children: childrenType.isRequired,
	description: PropTypes.string,
	searchInput: searchInputType,
	title: PropTypes.string.isRequired,
};
