import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Loader } from "../../common/components/Loader";
import {
	actionsButtonDependsOnType,
	additionalActionsType,
	additionalSearchLinkType,
	additionalSearchType,
	contentCreditsType,
	disclaimersType,
	downloadType,
	footnoteSingleType,
	footnotesType,
	layoutSwitcherType,
	namespaceType,
	resultTableConfigType,
	searchesType,
	searchTitleType,
	tabSearchType,
	viewsType,
} from "../../common/types";
import { SearchActions } from "../actions/SearchActions";
import { BypassBlock } from "../header/BypassBlock";
import { NoSearchResults } from "../NoSearchResults";
import { ResultsPreviewInitializer } from "../preview/ResultsPreviewInitializer";
import { SearchResultsPreviewContainer } from "../preview/SearchResultsPreviewContainer";
import useSearchState from "../useSearchState";
import { Paging } from "./paging/Paging";
import { Showing } from "./paging/Showing";
import { ResultsHeader } from "./ResultsHeader";
import { SearchResults } from "./SearchResults";

export function SearchResultsContainer({
	namespace,
	searchTitle,
	download,
	searches,
	views,
	layoutSwitcher,
	pagingEnabled,
	additionalAction,
	additionalSearch,
	additionalSearchLink,
	actionsButtonDependsOn,
	tabSearch,
	resultTableConfig,
	disclaimers,
	footnotes,
	footnoteSingle,
	contentCredits,
}) {
	const {
		loading,
		error,
		paging,
		resultExists,
		resultIsInvalid,
		resultsAreLoaded,
		warnings,
	} = useSearchState([""]);

	let content;
	let pagination;
	let showing;

	if (loading) {
		content = <Loader label="Loading results..." />;
	} else if (error || !resultExists) {
		content = (
			<NoSearchResults
				error={error}
				resultIsInvalid={resultIsInvalid}
				additionalSearchLink={additionalSearchLink}
				warnings={warnings}
			/>
		);
	} else {
		content = (
			<SearchResults
				resultTableConfig={resultTableConfig}
				paging={paging}
				additionalSearchLink={additionalSearchLink}
				disclaimers={disclaimers}
				footnotes={footnotes}
				warnings={warnings}
				footnoteSingle={footnoteSingle}
				contentCredits={contentCredits}
			/>
		);
		pagination = pagingEnabled && <Paging paging={paging} />;
		showing = pagingEnabled && <Showing paging={paging} />;
	}

	const resultsPreview = <SearchResultsPreviewContainer />;

	return (
		<div className="search-results-container">
			<ResultsPreviewInitializer namespace={namespace} />
			<ResultsHeader
				actionsButtonDependsOn={actionsButtonDependsOn}
				resultsPreview={resultsPreview}
				searchTitle={searchTitle}
				pagination={pagination}
				hasAdditionalSearch={!!additionalSearch}
			/>

			<div className="search-results" id="searchResults">
				{tabSearch}

				{resultsAreLoaded && (
					<BypassBlock
						skipTo="resultsContainer"
						label="Skip to search results table"
					/>
				)}

				<SearchActions
					namespace={namespace}
					resultsPreview={resultsPreview}
					resultsAreLoaded={resultsAreLoaded}
					download={download}
					searchesEnabled={searches.searchesEnabled}
					views={views}
					layoutSwitcher={layoutSwitcher}
					additionalAction={additionalAction}
					additionalSearch={additionalSearch}
				/>

				<div
					className={clsx("results-container", { loading })}
					id="resultsContainer"
				>
					{content}
				</div>
			</div>

			<footer className="search-footer grid">
				{showing}
				{pagination}
			</footer>
		</div>
	);
}

SearchResultsContainer.propTypes = {
	actionsButtonDependsOn: actionsButtonDependsOnType,
	additionalAction: additionalActionsType,
	additionalSearch: additionalSearchType,
	additionalSearchLink: additionalSearchLinkType,
	contentCredits: contentCreditsType,
	disclaimers: disclaimersType,
	download: downloadType.isRequired,
	footnotes: footnotesType,
	footnoteSingle: footnoteSingleType,
	layoutSwitcher: layoutSwitcherType,
	namespace: namespaceType.isRequired,
	pagingEnabled: PropTypes.bool.isRequired,
	resultTableConfig: resultTableConfigType.isRequired,
	searches: searchesType.isRequired,
	searchTitle: searchTitleType.isRequired,
	tabSearch: tabSearchType,
	views: viewsType.isRequired,
};
