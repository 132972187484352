import { getFocusableElements } from "./utils";

export const BYPASS_BLOCK_ACTIVE_CLASS = "bypass-block--active";

export function bypassBlocks() {
	const header = document.getElementById("header");
	const buttons = document.querySelectorAll(".bypass-block button");
	const topLineActiveClass = "breaking-news-enabled";

	if (buttons.length > 0) {
		buttons.forEach((button) => {
			const bypassBlock = button.parentElement;
			const isMainContentSkipLink =
				bypassBlock.getAttribute("id") === "mainContentBypassBlock";

			button.addEventListener("click", (e) => {
				const focusableElements = getFocusableElements(
					document.getElementById(e.target.dataset.block),
				);
				focusableElements[0].focus();
			});

			button.addEventListener("focusin", () => {
				bypassBlock.classList.add(BYPASS_BLOCK_ACTIVE_CLASS);

				if (isMainContentSkipLink) {
					header.classList.add(topLineActiveClass);

					if (header.classList.contains("scroll-down")) {
						header.classList.remove("scroll-down");
					}
				}
			});

			button.addEventListener("focusout", () => {
				bypassBlock.classList.remove(BYPASS_BLOCK_ACTIVE_CLASS);

				if (isMainContentSkipLink) {
					header.classList.remove(topLineActiveClass);
				}
			});
		});
	}
}
