import PropTypes from "prop-types";
import React from "react";
import { FavoriteListNameInput } from "./FavoriteListNameInput";
import { FavoritesAlert } from "./FavoritesAlert";

export function FavoriteLists({
	favorites,
	listsSelected,
	listsToDeleteCodeFrom,
	createNew,
	selectedCode,
	lead,
	error,
	onCreateNewChange,
	onInputChange,
	onAddToLists,
	onDeleteFromLists,
	onCreateAlert,
}) {
	const showAlert = createNew
		? listsSelected.length === 0
		: listsSelected.length === 1;

	const handleToggle = (id, isInList) => {
		let addToLists;
		let deleteFromLists;

		if (listsSelected.includes(id)) {
			addToLists = listsSelected.filter((item) => item !== id);
		} else {
			addToLists = [...listsSelected, id];
		}

		// If selected code is in list, add to listsToDeleteCodeFrom if needed
		if (isInList) {
			if (listsToDeleteCodeFrom.includes(id)) {
				deleteFromLists = listsToDeleteCodeFrom.filter(
					(item) => item !== id,
				);
			} else {
				deleteFromLists = [...listsToDeleteCodeFrom, id];
			}
		} else {
			deleteFromLists = listsToDeleteCodeFrom;
		}

		onAddToLists(addToLists);
		onDeleteFromLists(deleteFromLists);
	};

	const handleCreateNew = () => {
		onCreateNewChange(!createNew);
	};

	const favoritesList = favorites
		.map((list, index) => {
			const { id, name, codes } = list;
			const isInList = list.codes.includes(selectedCode);
			return (
				<div key={index} className="checkbox-input">
					<input
						type="checkbox"
						id={`favoriteList${id}`}
						value={id}
						name="favoriteLists"
						checked={
							listsSelected.includes(id) ||
							(isInList && !listsToDeleteCodeFrom.includes(id))
						}
						onChange={() => handleToggle(id, isInList)}
					/>
					<label htmlFor={`favoriteList${id}`}>
						{name} (<span>{codes.length}</span>)
					</label>
				</div>
			);
		})
		.toArray();

	return (
		<>
			<p>{lead} to any of these lists or create your own:</p>
			<div className="modal__body">
				<section className="modal__section">
					<fieldset>
						<fieldset className="checkbox-list list-scrollable">
							{favoritesList}
						</fieldset>

						<fieldset>
							<div className="checkbox-input">
								<input
									type="checkbox"
									id="favoriteListsNew"
									name="favoriteListsNew"
									checked={createNew}
									onChange={handleCreateNew}
								/>
								<label htmlFor="favoriteListsNew">
									Create new list
								</label>
							</div>

							<div className="additional-fields">
								<FavoriteListNameInput
									autoFocus={createNew}
									error={error}
									onChange={onInputChange}
								/>
							</div>
						</fieldset>
					</fieldset>
				</section>

				{showAlert && <FavoritesAlert onCreateAlert={onCreateAlert} />}
			</div>
		</>
	);
}

FavoriteLists.propTypes = {
	favorites: PropTypes.object.isRequired,
	listsSelected: PropTypes.array.isRequired,
	listsToDeleteCodeFrom: PropTypes.array.isRequired,
	createNew: PropTypes.bool.isRequired,
	selectedCode: PropTypes.string,
	lead: PropTypes.string.isRequired,
	error: PropTypes.string,
	onCreateNewChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onAddToLists: PropTypes.func.isRequired,
	onDeleteFromLists: PropTypes.func.isRequired,
	onCreateAlert: PropTypes.func.isRequired,
};
