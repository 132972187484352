import useFetchData from "../api/useFetchData";

export default function useFetchChoices(
	endpoint,
	params = undefined,
	shouldFetch = true,
) {
	const { data, loading, error } = useFetchData({
		endpoint,
		params,
		shouldFetch,
	});

	return {
		choices: data,
		loading,
		error,
	};
}
