import React from "react";
import { Link } from "../../common/components/Link";
import { sanitizeHTML } from "../utils";

export const AIRCRAFT_URL = "/aircraft/";
export const ACCIDENTS_AND_INCIDENTS_URL = "/accidents-and-incidents/";
export const AIRCRAFT_DATA_URL = "/aircraft-data/";
export const AIRCRAFT_VARIANT_URL = "/aircraft-variants/";
export const AIRPORTS_URL = "/airports/";
export const ALLIANCES_URL = "/alliances/";
export const CONTINENT_URL = "/continents/";
export const COUNTRIES_URL = "/countries/";
export const ENTITIES_URL = "/entities/";
export const IT_SYSTEMS_URL = "/it-systems/";
export const METRO_GROUPS_URL = "/metrogroups/";
export const OPERATORS_URL = "/airlines/";
export const SUBDIVISIONS_URL = "/subdivisions/";
export const LESSORS_URL = "/lessors/";
export const FLIGHT_URL = "/flights/";

export const renderDefault = (value) => value;
export const renderCombinedDate = (value) => value.get("date");
export const renderHtml = (value) => (
	<div dangerouslySetInnerHTML={{ __html: sanitizeHTML(value) }} />
);
export const renderYesNoValue = (value) => (value === "1" ? "Yes" : "No");

export function renderLink(
	url,
	label,
	additionalText = "",
	target = "",
	showTitle = false,
) {
	if (additionalText !== "") {
		additionalText = " (" + additionalText + ")";
	}

	return (
		<>
			<Link
				label={label}
				url={url}
				target={target}
				showTitle={showTitle}
			/>
			{additionalText}
		</>
	);
}

export function renderValueLink(value) {
	return value.get("value")
		? renderLink(value.get("link"), value.get("value").toString())
		: "";
}

export function renderAircraftCertification(value) {
	return value.get("value");
}

export function renderAircraftDataLink(value) {
	return renderLink(AIRCRAFT_DATA_URL + value, value);
}

export function renderAircraftVariantsLink(value) {
	return renderLink(
		AIRCRAFT_VARIANT_URL + value.get("code"),
		value.get("name"),
	);
}

export function renderOperatorLink(value) {
	return renderLink(
		OPERATORS_URL + value.get("code"),
		value.get("name") || value.get("code"),
	);
}

export function renderOperatorCodeLink(chaCode) {
	return renderLink(OPERATORS_URL + chaCode, chaCode);
}

export function renderOperatorIataCodeLink(iataCode) {
	return iataCode && renderLink(OPERATORS_URL + "iata/" + iataCode, iataCode);
}

export function renderOperatorIcaoCodeLink(icaoCode) {
	return icaoCode && renderLink(OPERATORS_URL + "icao/" + icaoCode, icaoCode);
}

export function renderAirportLink(value) {
	if (!value.get("code")) {
		return null;
	}

	return renderLink(
		AIRPORTS_URL + value.get("code"),
		value.get("name") || value.get("code"),
	);
}

export function renderAirportCodeLink(value) {
	return renderLink(AIRPORTS_URL + value.get("code"), value.get("code"));
}

export function renderAirportLocationLink(value) {
	return renderLink(
		AIRPORTS_URL + value.get("code"),
		value.get("name"),
		value.get("code"),
	);
}

export function renderAirportLocationCodeLink(value) {
	return renderLink(AIRPORTS_URL + value, value);
}

export function renderContinentLink(value) {
	return renderLink(CONTINENT_URL + value, value);
}

export function renderCountryLink(value) {
	if (!value.get("name") || !value.get("code")) {
		return;
	}

	return renderLink(COUNTRIES_URL + value.get("code"), value.get("name"));
}

export function renderCountryIsoLink(value) {
	return renderLink(COUNTRIES_URL + value, value);
}

export function renderCountryLocationCodeLink(value) {
	return renderLink(COUNTRIES_URL + value, value);
}

export function renderEmailLink(value) {
	return renderLink("mailto:" + value, value);
}

export function renderEntityLink(value) {
	const label = value.get("name") || value.get("name_long");

	if (!label) {
		return;
	}

	return renderLink(ENTITIES_URL + value.get("code"), label);
}

export function renderEntityCodeLink(value) {
	return renderLink(ENTITIES_URL + value, value);
}

export function renderLinkedInLink(value) {
	return renderLink(value, "Linkedin", "", "_blank");
}

export function renderMetroGroupLink(value) {
	return renderLink(METRO_GROUPS_URL + value.get("code"), value.get("name"));
}

export function renderMetroGroupCodeLink(value) {
	return renderLink(METRO_GROUPS_URL + value.get("code"), value.get("code"));
}

export function renderMetroGroupSimpleCodeLink(value) {
	return renderLink(METRO_GROUPS_URL + value, value);
}

export function renderLongRemarks(remark) {
	const content = remark.get("long_text");

	if (!content) {
		return null;
	}

	const count = 70;
	const hideContent = content.length > count;

	const handleReadMoreClick = (e) => {
		const remarkText = e.target.previousSibling;
		const buttonText = e.target.textContent;
		remarkText.classList.toggle("opened");
		e.target.textContent =
			buttonText === "Read more" ? "Read less" : "Read more";
	};

	return (
		<>
			<span className="remark-text">{content}</span>
			{hideContent && (
				<button
					type="button"
					className="btn btn--link"
					onClick={handleReadMoreClick}
				>
					Read more
				</button>
			)}
		</>
	);
}

export function renderSubdivisionLink(value) {
	const code = value.get("code");
	const countryCode = value.get("country_code");
	const label = value.get("name");

	return renderLink(SUBDIVISIONS_URL + countryCode + "/" + code, label);
}

export function getWebsiteUrl(value) {
	const isProtocolProvided =
		value.substring(0, 7) === "http://" ||
		value.substring(0, 8) === "https://";
	return isProtocolProvided ? value : "https://" + value;
}
