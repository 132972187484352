import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CloseButton } from "../../../common/components/modals/CloseButton";
import { Modal } from "../../../common/components/modals/Modal";
import { ModalFooter } from "../../../common/components/modals/ModalFooter";
import useModal from "../../../common/components/modals/useModal";
import { namespaceType } from "../../../common/types";
import { useLocalSearchView } from "../../../common/useLocalSearchView";
import { visibleOnUserInput } from "../../utils";
import useSelectView from "../useSelectView";
import useView from "../useView";
import { ViewNameInput } from "../ViewNameInput";
import { CurrentViewBadge } from "./CurrentViewBadge";
import useLoadViews from "./useLoadViews";
import { ViewItem } from "./ViewItem";

export function LoadView({ modalKey, namespace }) {
	const { views, loading, current, deletingError, onSelectViewToDelete } =
		useView(namespace);
	const { error } = useLoadViews(namespace);
	const [value, setValue] = useState("");
	const [selectedViewId, setSelectedViewId] = useState(
		current ? current.id : 0,
	);
	const { onSelectView } = useSelectView();
	const { clearModals, onCloseModal } = useModal();
	const { clearSearchView } = useLocalSearchView(namespace);

	const handleSelect = (id) => {
		setSelectedViewId(id);
	};

	const handleSelectClick = () => {
		if (selectedViewId === 0) {
			clearSearchView();
		}
		onSelectView(selectedViewId);
		clearModals();
	};

	let body;
	let buttons;

	if (loading) {
		body = <p>Loading views...</p>;
	} else if (error) {
		body = (
			<p className="error-text" role="alert">
				An error occurred: {error.message}.
			</p>
		);
		buttons = <CloseButton onClick={() => onCloseModal(modalKey)} />;
	} else if (views.isEmpty()) {
		body = <p>You have not saved any views yet.</p>;
		buttons = <CloseButton onClick={() => onCloseModal(modalKey)} />;
	} else {
		let viewsList;

		viewsList = views
			.valueSeq()
			.map((view) => {
				const visible = visibleOnUserInput(view.name, value);

				return (
					visible && (
						<ViewItem
							key={view.id}
							view={view}
							current={current}
							selected={selectedViewId}
							error={deletingError}
							onSelect={handleSelect}
							onSelectViewToDelete={onSelectViewToDelete}
						/>
					)
				);
			})
			.toArray();

		viewsList.unshift(
			<div key={0} className="radio-item">
				<div className="radio-input">
					<input
						type="radio"
						id="load-view-0"
						name="load-view"
						checked={selectedViewId === 0}
						onChange={() => handleSelect(0)}
					/>
					<label htmlFor="load-view-0">
						Default view{" "}
						<CurrentViewBadge isCurrentView={!current} />
					</label>
				</div>
			</div>,
		);

		body = (
			<div className="modal__body">
				<ViewNameInput onChange={(e) => setValue(e.target.value)} />

				<fieldset
					className={clsx("radio-list", {
						"list-scrollable": viewsList.length > 5,
					})}
				>
					{viewsList}
				</fieldset>
			</div>
		);

		buttons = (
			<>
				<CloseButton
					classes="btn btn--secondary"
					onClick={() => onCloseModal(modalKey)}
					label="Cancel"
				/>
				<button
					type="button"
					className="btn btn--primary"
					onClick={handleSelectClick}
				>
					Load View
				</button>
			</>
		);
	}

	const alignContent = clsx({
		"modal__container--center": loading || error || views.isEmpty(),
	});

	return (
		<Modal
			modalKey={modalKey}
			title="Load View"
			alignContent={alignContent}
		>
			<div className={clsx("modal__content", { loading, error })}>
				{body}
			</div>

			<ModalFooter>{buttons}</ModalFooter>
		</Modal>
	);
}

LoadView.propTypes = {
	modalKey: PropTypes.string.isRequired,
	namespace: namespaceType.isRequired,
};
