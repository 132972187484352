import PropTypes from "prop-types";
import React from "react";
import useModal from "../../common/components/modals/useModal";
import {
	advancedSearchAdditionalLinkType,
	namespaceType,
} from "../../common/types";
import { SearchesActions } from "../searches/SearchesActions";
import useSearches from "../searches/useSearches";

export function AdvancedSearchFooter({
	advancedSearchAdditionalLink,
	namespace,
	onClearClick,
	onSearchClick,
	searchesEnabled,
}) {
	const { current, searchDirty, updateSearch } = useSearches(namespace);
	const { clearModals } = useModal([]);

	return (
		<footer className="modal__actions">
			<div className="actions__container">
				<div className="actions__group">
					{searchesEnabled && (
						<SearchesActions
							current={current}
							searchDirty={searchDirty}
							updateSearch={updateSearch}
						/>
					)}
				</div>
				<div className="actions__group">
					{advancedSearchAdditionalLink}
					<button
						type="button"
						className="btn btn--link btn--clear"
						onClick={onClearClick}
					>
						Clear All
					</button>
					<button
						type="button"
						className="btn btn--tertiary"
						onClick={clearModals}
					>
						Cancel
					</button>
					<button
						type="button"
						className="btn btn--primary"
						onClick={onSearchClick}
					>
						Apply Filters
					</button>
				</div>
			</div>
		</footer>
	);
}

AdvancedSearchFooter.propTypes = {
	advancedSearchAdditionalLink: advancedSearchAdditionalLinkType,
	onClearClick: PropTypes.func.isRequired,
	onSearchClick: PropTypes.func.isRequired,
	namespace: namespaceType.isRequired,
	searchesEnabled: PropTypes.bool.isRequired,
};
