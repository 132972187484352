import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "../../../../common/components/dropdown/Dropdown";

export const YearPicker = ({ id, label, onChange, value, years }) => {
	const handleYearChange = (year) => onChange(year.toString());

	return (
		<>
			<Dropdown
				activeItem={value.toString()}
				dropdownKey={id}
				dropdownItems={years}
				dropdownToggle={value.toString()}
				label={label}
				onDropdownItemClick={handleYearChange}
				data-criterion
				scrollToView
				preselected={!!value}
			/>
		</>
	);
};

YearPicker.propTypes = {
	id: PropTypes.string.isRequired,
	filterDate: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	years: PropTypes.array.isRequired,
	criterionKey: PropTypes.string,
	label: PropTypes.string,
};
