import { Range } from "immutable";
import PropTypes from "prop-types";
import React from "react";
import { PagingGoTo } from "./PagingGoTo";
import { PagingLink } from "./PagingLink";

export function PagingBar({ pages, currentPage, onPageSelected }) {
	const padding = 1;
	const startPage = 0;
	const previousPage = Math.max(0, currentPage - 1);
	const lowestPage = Math.max(0, currentPage - padding);
	const highestPage = Math.min(currentPage + padding, pages - 1);
	const lastPage = Math.max(0, pages - 1);
	const nextPage = Math.min(lastPage, currentPage + 1);

	const lowGap = lowestPage > 0 && <span className="inactive">...</span>;
	const highGap = highestPage < pages - 1 && (
		<span className="inactive">...</span>
	);

	const links = Range(lowestPage, highestPage + 1)
		.map((p) => {
			return (
				<PagingLink
					key={p}
					page={p}
					selectedPage={currentPage}
					onPageSelected={onPageSelected}
				>
					{p + 1}
				</PagingLink>
			);
		})
		.toArray();

	const firstPageLink = lowestPage > 0 && (
		<PagingLink
			page={startPage}
			selectedPage={currentPage}
			onPageSelected={onPageSelected}
		>
			{startPage + 1}
		</PagingLink>
	);

	const lastPageLink = highestPage < pages - 1 && (
		<PagingLink
			page={lastPage}
			selectedPage={currentPage}
			onPageSelected={onPageSelected}
		>
			{lastPage + 1}
		</PagingLink>
	);

	const prevPageLink = currentPage !== startPage && (
		<PagingLink
			page={previousPage}
			selectedPage={currentPage}
			onPageSelected={onPageSelected}
			navBtn
		>
			Go to previous page
		</PagingLink>
	);
	const nextPageLink = currentPage !== lastPage && (
		<PagingLink
			page={nextPage}
			selectedPage={currentPage}
			onPageSelected={onPageSelected}
			navBtn
		>
			Go to next page
		</PagingLink>
	);

	return (
		<nav className="paging-bar">
			<div className="paging-list">
				{prevPageLink}
				{firstPageLink}
				{lowGap}
				{links}
				{highGap}
				{lastPageLink}
				{nextPageLink}
			</div>

			<PagingGoTo currentPage={currentPage} onClick={onPageSelected} />
		</nav>
	);
}

PagingBar.propTypes = {
	pages: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	onPageSelected: PropTypes.func.isRequired,
};
