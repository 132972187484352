import { isEqual, omitBy } from "lodash";

export const useLocalNewsType = () => {
	const getStoredLocalType = () =>
		JSON.parse(localStorage.getItem(LOCAL_NEWS_TYPE_KEY));
	const storeLocalType = (newsTypeInput) => {
		if (
			!newsTypeInput ||
			isEqual(
				omitBy(newsTypeInput, (v) => v == null),
				getStoredLocalType(),
			)
		) {
			return;
		}
		localStorage.setItem(
			LOCAL_NEWS_TYPE_KEY,
			JSON.stringify(newsTypeInput),
		);
	};
	const clearLocalType = () => localStorage.removeItem(LOCAL_NEWS_TYPE_KEY);

	return {
		getStoredLocalType,
		storeLocalType,
		clearLocalType,
	};
};

export const LOCAL_NEWS_TYPE_KEY = "news_search_local_type";
