import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Accordion } from "../../../../common/components/accordion/Accordion";
import { Overlay } from "../../../../common/components/modals/Overlay";
import useModal from "../../../../common/components/modals/useModal";
import {
	newErrorToast,
	TOAST_QUICK_SEARCH_DEFINE_FIVE,
} from "../../../../common/components/toasts/constants";
import useToasts from "../../../../common/components/toasts/useToasts";
import {
	criteriaGroupsType,
	customizeQuickSearchType,
	namespaceType,
} from "../../../../common/types";
import useSearchState from "../../../useSearchState";
import { CustomizeQuickSearchGroup } from "./CustomizeQuickSearchGroup";
import { useCustomizeQuickSearch } from "./useCustomizeQuickSearch";

export function CustomizeQuickSearch({
	criteria,
	customizeQuickSearch,
	namespace,
	quickSearchCriteriaOrder,
}) {
	const { currentInput } = useSearchState();
	const {
		input,
		selectedCriteria,
		onInputChange,
		onSelectedCriteriaChange,
		resetCriteria,
		updateCriteria,
	} = useCustomizeQuickSearch(namespace, currentInput);
	const { clearModals } = useModal();
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const { onAddToast } = useToasts();
	const radios = customizeQuickSearch.radios || [];

	const selectedCriteriaCount = selectedCriteria.size;
	const maxCountOfCriteriaSelected = selectedCriteriaCount === 5;

	const handleSelectedCriteriaChange = (criteria) => {
		onSelectedCriteriaChange(
			quickSearchCriteriaOrder
				? criteria.sort(
						(a, b) =>
							quickSearchCriteriaOrder[a] -
							quickSearchCriteriaOrder[b],
					)
				: criteria,
		);
	};

	const handleSaveCriteria = () => {
		if (selectedCriteriaCount !== 5) {
			onAddToast(TOAST_QUICK_SEARCH_DEFINE_FIVE);
		} else {
			setLoading(true);

			const successHandler = () => {
				setLoading(false);
				clearModals();
			};

			const failureHandler = (error) => {
				onAddToast(
					newErrorToast(_.camelCase(error.message), error.message),
				);
				setLoading(false);
			};

			updateCriteria(
				selectedCriteria,
				input,
				successHandler,
				failureHandler,
			);
		}
	};

	const handleResetCriteria = () => {
		setDeleting(true);

		const successHandler = () => {
			setDeleting(false);
			clearModals();
		};

		const failureHandler = (error) => {
			onAddToast(
				newErrorToast(_.camelCase(error.message), error.message),
			);
			setDeleting(false);
		};

		resetCriteria(successHandler, failureHandler);
	};

	return (
		<Overlay
			title="Customise filters"
			description={`Add or remove 5 filters to your quick search. ${selectedCriteriaCount} of 5 filters selected.`}
			additionalClasses="customize-quick-search"
		>
			<div className="search-criteria modal__content">
				<Accordion>
					{criteria.map((group) => {
						const groupRadios = radios.filter((radio) =>
							Object.keys(group.criteria).includes(radio),
						);
						return (
							<CustomizeQuickSearchGroup
								key={group.name}
								group={group}
								input={input}
								onInputChange={onInputChange}
								onSelectedCriteriaChange={
									handleSelectedCriteriaChange
								}
								radios={groupRadios}
								maxCountOfCriteriaSelected={
									maxCountOfCriteriaSelected
								}
								selectedCriteria={selectedCriteria}
							/>
						);
					})}
				</Accordion>
			</div>

			<footer className="modal__actions">
				<div className="actions__container">
					<div className="actions__group">
						<button
							type="button"
							className="btn btn--tertiary"
							onClick={handleResetCriteria}
						>
							{deleting ? "Resetting..." : "Reset to default"}
						</button>
						<button
							type="button"
							className="btn btn--primary"
							onClick={handleSaveCriteria}
						>
							{loading ? "Saving..." : "Save"}
						</button>
					</div>
				</div>
			</footer>
		</Overlay>
	);
}

CustomizeQuickSearch.propTypes = {
	criteria: criteriaGroupsType.isRequired,
	customizeQuickSearch: customizeQuickSearchType,
	namespace: namespaceType.isRequired,
	quickSearchCriteriaOrder: PropTypes.object,
};
